import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lang'
})

export class LangPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (value) {
      return value[arg];
    }
  }

}
