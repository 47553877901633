import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MessageComponent } from 'src/app/components/message/message.component';
import { IMessage, IMessagePopup } from 'src/app/interfaces/IMessage';
import { AuthStore } from 'src/app/stores/auth.store';
import { CartStore } from 'src/app/stores/cart.store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IOrder } from 'src/app/interfaces/IOrder';
import { DataService } from 'src/app/services/data.service';
import { OrderStore } from 'src/app/stores/order.store';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit {

  form: FormGroup;


  opt: number = 1;
  user: any;
  process: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authStore: AuthStore,
    public cartStore: CartStore,
    private fb: FormBuilder,
    private ds: DataService,
    private orderStore: OrderStore,
    public language: LanguageStore
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.compose([Validators.required, Validators.pattern("[0]{1}[1-9]{1}[0-9]{7,8}")])],
      address: [null, Validators.required]
    });
    this.authStore.checkAuth((user) => {
      this.cartStore.fetchCart(user.key, () => { });
      this.user = user;
      this.form.patchValue({
        name: user.name,
        phone: user.contact_phone,
        address: user.address
      })
    });
  }
  goDetail(path: any) {
    this.router.navigate([path]);
  }

  updateQTY(op: string, item: any, product?: any) {
    if (op == "-") {
      if (item.qty > 1 && (item.qty * product.unit_per_sale) > product.min_qty) {
        this.cartStore.updateQty(this.user.key, item, -1);
      }
    } else if (op == "+") {
      this.cartStore.updateQty(this.user.key, item, 1);
    }
  }

  removeItem(item: any, product: any) {
    const data: IMessage = {
      title: {
        km: "ដកចេញពីកន្ត្រក",
        en: "Remove from Cart"
      },
      msg: {
        km: "តើអ្នកចង់ដក​​​ចេញពីកន្ត្រកមែនឬទេ?",
        en: "Do you want to remove it from the cart?"
      },
      textButton: {
        km: "ដកចេញ",
        en: "Remove"
      },
    };
    const result = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
    result.afterClosed().subscribe((res) => {
      if (res) {
        this.cartStore.removeCart(this.user.key, item.key, (success) => {
          if (!success) {
            this.dialogMessage({
              title: {
                km: "បរាជ័យ",
                en: "Fault"
              },
              msg: {
                km: "ប្រតិបត្តិការណ៍បានបរាជ័យ សូមព្យាយាមម្ដងទៀត។",
                en: "Process Fault, Please Try Again"
              },
              duration: 3
            });
          }
        });
      }
    })

  }


  onOrder(form: any, productList: any[]) {
    if (this.form.valid) {
      const data: IMessage = {
        title: {
          km: "ដាក់ការបញ្ជាទិញ",
          en: "Place an order"
        },
        msg: {
          km: "តើអ្នកចង់ដាក់ការបញ្ជាទិញឥលូវមែនឬទេ?",
          en: "Do you want to place an order now?"
        },
        textButton: {
          km: "បញ្ជាទិញ",
          en: "Order Now"
        },
      };
      const result = this.dialog.open(ConfirmDialogComponent, {
        data: data,
        panelClass: 'confirm-dialog',
        width: '300px',
        autoFocus: false
      });
      result.afterClosed().subscribe((res) => {
        this.orderStore.getLastOrder((lastOrder) => {
          let lastOrderNo = 1;
          if (lastOrder.length > 0) {
            lastOrderNo = Number(lastOrder[0].order_no.replace( /^\D+/g, '')) + 1;
          }
          if (res) {
            this.process = true;
            const { name, phone, address } = form;
            const product = [];
            let total = 0;
            let discount = 0;
            productList.map((item) => {
              product.push({
                qty: item.cart.qty,
                key: item.product.key,
                name: item.product.name,
                description: item.product.description,
                thumbnail: item.product.thumbnail,
                price: Number(item.product.price),
                discount: item.product.discount,
                uom: item.product.uom,
                min_qty: item.product.min_qty,
                unit_per_sale: item.product.unit_per_sale,
              });
              const totalPerUnit = item.product.price - (item.product.price * (item.product.discount / 100));
              total += (totalPerUnit * item.cart.qty);
              const discountPerUnit = item.product.price * (item.product.discount / 100);
              discount += (discountPerUnit * item.cart.qty);
            });
            const item: IOrder = {
              key: this.ds.createKey(),
              product: product,
              name: name,
              phone: phone,
              address: address,
              created_by: this.user,
              created_date: new Date,
              order_no: `FF0000${lastOrderNo}`,
              status: {
                key: 1,
                text: "Pending"
              },
              total_discount: discount,
              total_price: total
            };
            this.orderStore.save(item, (res) => {
              if (res) {
                this.process = false;
                productList.map((item) => {
                  this.cartStore.removeCart(this.user.key, item.cart.key, () => { });
                });
                this.dialogMessage({
                  title: {
                    km: "ជោគជ័យ",
                    en: "Success"
                  },
                  msg: {
                    km: "ប្រតិបត្តិការណ៍ទទួលបានជោគជួយ។",
                    en: "Success"
                  },
                  duration: 3
                });
              } else {
                this.process = false;
                this.dialogMessage({
                  title: {
                    km: "បរាជ័យ",
                    en: "Fault"
                  },
                  msg: {
                    km: "ប្រតិបត្តិការណ៍បានបរាជ័យ សូមព្យាយាមម្ដងទៀត។",
                    en: "Process Fault, Please Try Again"
                  },
                  duration: 3
                });
              }
            });
          }
        });
      });
    } else {
      this.form.markAllAsTouched();
    }

  }

  trackByFn(index, item) {
    return item.key;
  }

  dialogMessage(option: IMessagePopup) {
    this.dialog.open(MessageComponent, {
      data: option,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
  }

}
