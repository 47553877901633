<div class="buy-now" [formGroup]="form">
  <div class="container-buy-now">
    <div class="image">
      <img [src]="data?.thumbnail" #img (error)="img.src='./assets/images/logo/logo-removebg-preview (1).png'" alt="">
    </div>
    <div class="title-buy">
      <h3>{{ {en:data.name.en, km:data.name.km} | lang: language.lang }}</h3>

      <h3 *ngIf="data.min_qty && data.min_qty > data?.unit_per_sale">{{ {en:"Total Price", km:"តម្លៃសរុប"} | lang: language.lang }} :
        {{data?.price * (data?.min_qty / data?.unit_per_sale) | number:'1.0'}} ៛</h3>

      <h3 *ngIf="!data.min_qty || data.min_qty <= data?.unit_per_sale">តម្លៃសរុប : {{data?.price | number:'1.0'}} ៛</h3>

      <span class="min-qty" *ngIf="data.min_qty && data.min_qty > data?.unit_per_sale">*{{ {en:'Selling from
        '+data.min_qty+data.uom.name?.en+' up',
        km:'លក់ចាប់ពី '+data.min_qty+data.uom.name?.km+' ឡើងទៅ'} | lang:
        language.lang }}</span>
    </div>
    <div class="form">
      <div class="fill name">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{ {en:"Name", km:"ឈ្មោះ"} | lang: language.lang }}</mat-label>
          <input matInput formControlName="name"
            [placeholder]='{en:"Enter name", km:"បញ្ចលឈ្មោះ"} | lang: language.lang'>
          <mat-icon matSuffix>account_circle</mat-icon>
        </mat-form-field>
      </div>
      <div class="fill phone">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{ {en:"Phone", km:"លេខទូរស័ព្ទ"} | lang: language.lang }}</mat-label>
          <input matInput formControlName="phone"
            [placeholder]='{en:"Enter phone", km:"បញ្ចលលេខទូរស័ព្ទ"} | lang: language.lang'>
          <mat-icon matSuffix>local_phone</mat-icon>
        </mat-form-field>
      </div>
      <div class="fill text">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>{{ {en:"Address", km:"អាសយដ្ឋាន"} | lang: language.lang }}</mat-label>
          <textarea matInput formControlName="address"
            [placeholder]='{en:"Enter address", km:"បញ្ចលអាសយដ្ឋាន"} | lang: language.lang'></textarea>
        </mat-form-field>
      </div>
    </div>
    <button mat-flat-button class="button-buy-now" (click)="onSubmit()"><span>{{ {en:"Buy Now", km:"ទិញឥលូវនេះ"} | lang:
        language.lang }}</span></button>
  </div>
</div>