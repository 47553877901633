import { AuthStore } from 'src/app/stores/auth.store';
import { ForgetPasswordComponent } from './../forget-password/forget-password.component';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { AuthService } from 'src/app/auth/auth.service';
import * as firebase from 'firebase';
import { IUser } from 'src/app/interfaces/IUser';
import { WindowService } from 'src/app/services/window.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup;
  phone: AbstractControl;

  verifyForm: FormGroup;
  code: AbstractControl;

  hide = true;

  sentCode: boolean;

  windowRef: any;
  loading: boolean;

  constructor(
    private dialogRef: MatDialogRef<SignInComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authStore: AuthStore,
    private authService: AuthService,
    private win: WindowService,
    private auth: AngularFireAuth,
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      phone: [null, Validators.compose([Validators.required, Validators.pattern("[0]{1}[1-9]{1}[0-9]{7,8}")])],
    });
    this.phone = this.loginForm.controls['phone'];

    this.verifyForm = this.fb.group({
      code: [null, Validators.required],
    });
    this.code = this.verifyForm.controls['code'];
  }

  ngAfterViewInit(): void {
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  }


  onSubmit(form: any) {
    if (this.loginForm.valid) {
      this.loading = true;
      this.loginForm.disable();
      const { phone } = form;
      const phone_number = '+855' + Number(phone);
      const appVerifier = this.windowRef.recaptchaVerifier;
      this.authStore.uniquePhone(phone, (res) => {
        if (res) {
          this.auth.signInWithPhoneNumber(phone_number, appVerifier)
            .then(result => {
              this.windowRef.confirmationResult = result;
              this.loading = false;
              this.loginForm.enable();
              this.sentCode = true;
            })
            .catch(error => {
              this.loading = false;
              this.loginForm.enable();
              console.log(error);
            });
        } else {
          this.loading = false;
          this.loginForm.enable();
          this.phone.setErrors({ unique: true });
        }
      })
    } else {
      console.log(this.loginForm.errors);
    }
  }

  onVerify(f: any) {
    if (this.verifyForm.valid) {
      this.loading = true;
      this.code.setErrors({ wrong_code: false });
      this.verifyForm.disable();
      this.windowRef.confirmationResult
        .confirm(f.code)
        .then((result: any) => {
          this.dialogRef.close();
        })
        .catch((error: any) => {
          console.log(error);
          this.loading = false;
          this.verifyForm.enable();
          this.code.setErrors({ wrong_code: true });
        });
    }
  }

  close() {
    this.dialogRef.close();
  }

  signInWithFB() {
    this.authStore.FacebookAuth((res) => {
      if (res) {
        this.close();
      }
    });
  }

  popsignup() {
    this.dialog.open(SignUpComponent, {
      width: "700px",
      panelClass: "dialog-signup-class",
      autoFocus: false,

    });
  }
  popForgetPw() {
    this.dialog.open(ForgetPasswordComponent, {
      width: "500px",
      panelClass: "forget-pw-class",
      autoFocus: false,
    });
  }


}
