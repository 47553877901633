import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Pipe({
  name: 'docRef'
})
export class DocRefPipe implements PipeTransform {

  constructor(
    private db: AngularFirestore
  ) { }

  transform(key: string): any {
    const ref = this.db.collection('products').doc(key).ref;
    return ref.get().then(doc => {
      return doc.data();
    });
  }

}
