import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  email: AbstractControl;
  loginForm: FormGroup;

  loginValidate = {
    email: {
      required: false,
      pattern: false
    },
  }
  constructor(
    private dialogRef: MatDialogRef<ForgetPasswordComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9._]{5,30}[@][a-zA-Z]{2,20}[.][a-zA-Z]{2,20}")])],
    });
    this.email = this.loginForm.controls['email'];
  }
  close() {
    this.dialogRef.close();
  }

}
