import { Component, OnInit } from '@angular/core';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
  }

}
