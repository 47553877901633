import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactStore } from 'src/app/stores/contact.store';
import { LanguageStore } from 'src/app/stores/language.store';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  email: AbstractControl

  formValidate = {
    email: {
      required: true,
      pattern: false
    },
    phone: {
      required: false,
      pattern: false
    },
    description: {
      required: false
    }
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private fb: FormBuilder,
    public contactStore: ContactStore,
    public language: LanguageStore,
  ) { }
  ngFormBuild() {
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9._]{5,30}[@][a-zA-Z]{2,20}[.][a-zA-Z]{2,20}")])],
      phone: [null, Validators.compose([Validators.required, Validators.pattern("[0]{1}[1-9]{1}[0-9]{7,8}")])],
      message: [null, Validators.required]
    })
  }
  ngOnInit(): void {
    this.ngFormBuild();
    this.contactStore.fetchContact();
  }
}



