<ng-container *ngIf="!productStore.loading">
  <div class="detail">
    <div class="has-fixed">
      <div class="content fixed">
        <div class="container-detail">
          <div class="left">
            <div class="image">
              <div class="big-image" *ngIf="data?.thumbnail">
                <a
                  *ngFor="let item of data?.gallery; index as i"
                  [href]="item"
                  [style.display]="indexSelected == i ? 'block' : 'none'"
                  data-fancybox="images"
                  [attr.data-caption]="data?.name"
                >
                  <img class="thumbnail" [src]="showImage || item" alt="" />
                </a>
              </div>

              <div class="small-image" *ngIf="data?.gallery?.length > 1">
                <a
                  class="gallery"
                  *ngFor="let item of data?.gallery; index as i"
                  (click)="imageClick(item, null, i)"
                >
                  <img [src]="item" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="info-detail">
              <div class="title-detail">
                <h3>
                  {{
                    { en: data?.name?.en, km: data?.name?.km }
                      | lang : language.lang
                  }}
                </h3>
              </div>
              <div class="price-product">
                <h3>{{ data?.price | number : "1.0" }} ៛ /</h3>
                <span class="small-price">
                  {{ data?.unit_per_sale
                  }}{{
                    { en: data?.uom?.name?.en, km: data?.uom?.name?.km }
                      | lang : language.lang
                  }}</span
                >
                <div class="discount" *ngIf="data?.discount > 0">
                  <h3>
                    -
                    {{
                      { en: data?.discount?.en, km: data?.discount?.km }
                        | lang : language.lang
                    }}
                    %
                  </h3>
                </div>
              </div>
              <div class="info-des">
                <p>
                  {{
                    { en: data?.description?.en, km: data?.description?.km }
                      | lang : language.lang
                  }}
                </p>
              </div>
              <div class="total-item">
                <span class="stock" *ngIf="!data?.in_stock">{{
                  { en: "*Out of Stock", km: "អស់ពី​ស្តុក" }
                    | lang : language.lang
                }}</span>
                <p class="min-qty" *ngIf="data.min_qty && data.min_qty > 1">
                  {{
                    {
                      en:
                        "Selling from " +
                        data.min_qty +
                        data.uom.name?.en +
                        " up",
                      km:
                        "លក់ចាប់ពី " +
                        data.min_qty +
                        data.uom?.name?.km +
                        " ឡើងទៅ"
                    } | lang : language.lang
                  }}
                </p>
                <div class="quantity">
                  <div class="qty">
                    <div class="btn minus" (click)="qty('-')">
                      <i class="fas fa-minus"></i>
                    </div>
                    <div class="qty-num">
                      <span id="qty">{{
                        { data: data, qty: opt * data.unit_per_sale }
                          | convertUom : language.lang
                      }}</span>
                    </div>
                    <div class="btn plus" (click)="qty('+')">
                      <i class="fas fa-plus"></i>
                    </div>
                  </div>
                </div>
                <!-- <div class="total-price">
                  {{ {en:'Total', km:'តម្លៃសរុប'} | lang: language.lang }} :
                  <span>​​{{totalPrice | number:'1.0'}}
                    រៀល</span>
                </div> -->
              </div>
              <div class="button-buy">
                <div class="buy-cart">
                  <!-- <button
                    mat-flat-button
                    class="buy"
                    (click)="popBuyNow(data, opt)"
                    [disabled]="
                      data.min_qty > opt * data.unit_per_sale || !data.in_stock
                    "
                  >
                    <span>{{
                      { en: "Buy Now", km: "ទិញឥឡូវនេះ" } | lang : language.lang
                    }}</span>
                  </button> -->
                  <button
                    mat-flat-button
                    class="cart"
                    [disabled]="
                      data.min_qty > opt * data.unit_per_sale || !data.in_stock
                    "
                    (click)="addToCart(data)"
                  >
                    <span>{{
                      { en: "Add To Cart", km: "បន្ថែមទៅកន្រ្តក" }
                        | lang : language.lang
                    }}</span>
                  </button>
                </div>
                <div class="call">
                  <button
                    class="facebook"
                    (click)="social(contactStore?.data?.social?.facebook, true)"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </button>
                  <button
                    class="call"
                    (click)="call('tel:' + contactStore?.data?.phone)"
                  >
                    <i class="fas fa-phone-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="relate-product">
          <div class="main-title">
            <div class="container">
              <div class="text">
                <span
                  >{{ { en: "Product", km: "ផលិតផល" } | lang : language.lang }}
                  <i class="fas fa-chevron-right"></i
                ></span>
              </div>
            </div>
            <div class="underline"></div>
          </div>
          <div class="product-list">
            <div
              class="item-product"
              *ngFor="let item of productStore?.dataRelate"
            >
              <div class="image-left" (click)="toDetail(item?.key)">
                <div class="image">
                  <img [src]="item?.thumbnail" alt="" />
                  <div class="sub-img">
                    <img [src]="item?.thumbnail" alt="" />
                  </div>
                </div>
              </div>
              <div class="text">
                <div>
                  <div class="title" (click)="toDetail(item?.key)">
                    <span>{{
                      { en: item?.name?.en, km: item?.name?.km }
                        | lang : language.lang
                    }}</span>
                  </div>
                  <div
                    class="sell-min-qty"
                    (click)="toDetail(item?.key)"
                    *ngIf="item?.min_qty > 1"
                  >
                    <span>{{
                      {
                        en:
                          "Selling from " +
                          item.min_qty +
                          item.uom.name?.en +
                          " up",
                        km:
                          "លក់ចាប់ពី " +
                          item.min_qty +
                          item.uom.name?.km +
                          " ឡើងទៅ"
                      } | lang : language.lang
                    }}</span>
                  </div>
                  <div
                    class="stock"
                    (click)="toDetail(item?.key)"
                    *ngIf="!item?.in_stock"
                  >
                    <p>
                      {{
                        { en: "Out of Stock", km: "អស់ពី​ស្តុក" }
                          | lang : language.lang
                      }}
                    </p>
                  </div>
                </div>
                <div>
                  <div class="price" (click)="toDetail(item?.key)">
                    <h3>{{ item?.price | number : "1.0" }} ៛ /</h3>
                    <span class="small-price">
                      {{ item?.unit_per_sale
                      }}{{
                        { en: item?.uom?.name?.en, km: item?.uom?.name?.km }
                          | lang : language.lang
                      }}</span
                    >
                    <div class="discount" *ngIf="item?.discount > 0">
                      <h3>- {{ item.discount }} %</h3>
                    </div>
                  </div>
                  <div class="button">
                    <button
                      (click)="popBuyNow(item)"
                      [disabled]="!item?.in_stock"
                    >
                      {{
                        { en: "Buy Now", km: "ទិញឥឡូវនេះ" }
                          | lang : language.lang
                      }}
                    </button>
                    <div class="call">
                      <button
                        class="facebook"
                        (click)="addToCart(item, true)"
                        [disabled]="!item?.in_stock"
                      >
                        <i class="fas fa-cart-plus"></i>
                      </button>
                      <button
                        class="call"
                        (click)="call('tel:')"
                        [disabled]="!item?.in_stock"
                      >
                        <i class="fas fa-phone-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="productStore.loading" class="loading"></app-loading>
