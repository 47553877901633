<ng-container *ngIf="!contactStore.loading">
  <div class="contact">
    <div class="has-fixed"></div>
    <div class="content fixed">
      <div class="title">
        <h3>
          {{ { en: "Contact Us", km: "ទំនាក់ទំនង" } | lang : language.lang }}
        </h3>
        <div class="text">
          <span
            >{{
              {
                en: "If you have any question feel free to contact us",
                km: "ប្រសិនបើមានសំណួរណាមួយ? អ្នកអាចសរសេរសារមកយើង!"
              } | lang : language.lang
            }}
          </span>
        </div>
      </div>
      <div class="cotainer-contact">
        <div class="left">
          <div class="info">
            <div class="title-info">
              <p>
                {{
                  { en: "Our Information", km: "ព័ត៌មានរបស់យើង" }
                    | lang : language.lang
                }}
              </p>
            </div>
            <div class="text">
              <div class="phone">
                <p>
                  {{
                    { en: "Phone :", km: "លេខទូរស័ព្ទ :" }
                      | lang : language.lang
                  }}
                </p>
                <span>{{ contactStore?.data?.phone }}</span>
              </div>
              <div class="mail">
                <p>
                  {{
                    { en: "Email :", km: "អ៉ីម៉ែល :" } | lang : language.lang
                  }}
                </p>
                <span>{{ contactStore?.data?.email }}</span>
              </div>
              <div class="address">
                <p>
                  {{
                    { en: "Address :", km: "អាសយដ្ឋាន :" }
                      | lang : language.lang
                  }}
                </p>
                <span>{{
                  {
                    en: contactStore?.data?.address?.en,
                    km: contactStore?.data?.address?.km
                  } | lang : language.lang
                }}</span>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d250043.01309692345!2d104.8917088!3d11.6999313!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310953f067197a4d%3A0x25405794f96ddf9d!2sFunan%20Farm!5e0!3m2!1sen!2skh!4v1653911935437!5m2!1sen!2skh"
                width="100%"
                height="100%"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="image">
            <img
              src="./assets/images/photo/0d73f24840cff849a16a43286ce3cb33.webp"
              alt=""
            />
          </div>
          <!-- <div class="form">
            <div class="title-right">
              <p>{{ {en:'Form', km:'បំពេញទម្រង់'} | lang: language.lang }}</p>
            </div>
            <div class="inside-form">
              <form class="fill email">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>{{ {en:'Name', km:'ឈ្មោះ'} | lang: language.lang }}</mat-label>
                  <input matInput
                    placeholder="{{ {en:'Please Enter Your Name ...', km:'បញ្ចូលឈ្មោះទីនេះ ...'} | lang: language.lang }}">
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </form>
              <form class="fill name">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>{{ {en:'Email', km:'អ៉ីម៉ែល'} | lang: language.lang }}</mat-label>
                  <input matInput
                    placeholder="{{ {en:'Please Enter Your Email', km:'បញ្ចូលអ៉ីម៉ែលទីនេះ ...'} | lang: language.lang }}">
                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
              </form>
              <form class="fill phone">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>{{ {en:'Phone Number', km:'លេខទូរស័ព្ទ'} | lang: language.lang }}</mat-label>
                  <input matInput
                    placeholder="{{ {en:'Please Enter Your Phone Number', km:'បញ្ចូលទូរស័ព្ទទីនេះ ...'} | lang: language.lang }}">
                  <mat-icon matSuffix>local_phone</mat-icon>
                </mat-form-field>
              </form>
              <form class="fill text">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label>{{ {en:'Message', km:'សសេរសារ'} | lang: language.lang }}</mat-label>
                  <textarea matInput
                    placeholder="{{ {en:'Please Enter Your Message ...', km:'សសេរសារទីនេះ ...'} | lang: language.lang }}"></textarea>
                </mat-form-field>
              </form>
              <div class="button">
                <button mat-flat-button class="button-send"><span>{{ {en:'Send Message', km:'ផ្ញើរសារ'} | lang:
                    language.lang }}</span></button>
              </div>
            </div>


          </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="contactStore.loading" class="loading"></app-loading>
