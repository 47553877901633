<ng-container *ngIf="!categoryStore.loading">
  <div class="category">
    <div class="has-fixed"></div>
    <div class="content fixed">
      <div class="type-category">
        <div class="main-title">
          <div class="container">
            <div class="text">
              <span>{{ {en:'Category', km:'ប្រភេទផលិតផល'} | lang: language.lang }} <i
                  class="fas fa-chevron-right"></i></span>
            </div>
          </div>
          <div class="underline"></div>
        </div>

      </div>
      <div class="container-category">
        <div class="item-category" *ngFor="let item of
        categoryStore?.data" (click)="goCateKey(item?.key)">
          <div class="image">
            <img [src]="item?.image" alt="">
          </div>
          <p>{{ {en:item?.name?.en, km:item?.name?.km} | lang: language.lang }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="categoryStore.loading" class="loading"></app-loading>
