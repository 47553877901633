import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryStore } from 'src/app/stores/category.store';
import { LanguageStore } from 'src/app/stores/language.store';
import { ProductStore } from 'src/app/stores/product.store';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  panelOpenState = false;
  data: any;
  menuActive: string;
  constructor(
    private router: Router,
    public categoryStore: CategoryStore,
    private route: ActivatedRoute,
    public productStore: ProductStore,
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
    this.categoryStore.fetchCategory();

    // this.route.params.subscribe((param) => {
    //   this.productStore.fetchProduct(param.key, (data) => {
    //     this.data = data;
    //     console.log(data)
    //   });
    // });

  }

  goCateKey(id: any) {
    this.router.navigate(["category", id]);
  }

}
