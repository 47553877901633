import { AccountComponent } from './pages/account/account.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { CartListComponent } from './pages/cart-list/cart-list.component';
import { SearchListComponent } from './pages/search-list/search-list.component';
import { SubCategoryComponent } from './pages/sub-category/sub-category.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ProductsComponent } from './pages/products/products.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CategoryComponent } from './pages/category/category.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { DetailComponent } from './pages/detail/detail.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AuthGuard } from './auth/auth.guard';
import { PopupDetailComponent } from './components/popup-detail/popup-detail.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  // { path: 'detail/:key', component: DetailComponent },
  { path: 'detail/:key', component: PopupDetailComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'category', pathMatch: 'prefix', children: [
      { path: '', component: CategoryComponent },
      { path: ':cateKey', component: SubCategoryComponent },
      { path: ':cateKey/:subKey', component: SubCategoryComponent }
    ]
  },
  {
    path: 'account', component: AccountComponent, canActivate: [AuthGuard], pathMatch: 'prefix', children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent },
      { path: 'order-list', component: OrderListComponent }
    ]
  },
  { path: 'product/:cateKey', component: HomeComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'search-product/:keyword', component: SearchListComponent },
  // { path: 'profile', component: ProfileComponent },
  { path: 'cart-list', component: CartListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
