import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/stores/auth.store';
import { LanguageStore } from 'src/app/stores/language.store';
import { OrderStore } from 'src/app/stores/order.store';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  constructor(
    public orderStore: OrderStore,
    private auth: AuthStore,
    public language: LanguageStore
  ) { }

  ngOnInit(): void {
    this.auth.checkAuth((user) => {
      this.orderStore.fetchOrder(user.key);
    });
  }

}
