<div class="cart-list">
  <div class="has-fixed"></div>
  <div class="content fixed">
    <div class="container-cart-list">
      <div class="left">
        <div class="product-cart">

          <div class="item-cart" *ngFor="let item of cartStore?.cartList; trackBy: trackByFn">
            <ng-container *ngIf="$any(item?.product_key | docRef | async);let product">
              <div class="image">
                <img [src]="product.thumbnail" alt="">
              </div>
              <div class="text">
                <h3>{{ {en:product?.name?.en, km:product?.name?.km} | lang: language.lang }}</h3>
                <div class="delete-icon">
                  <button mat-icon-button class="delete-icon" (click)="removeItem(item,product)">
                    <mat-icon class="mat-18">delete</mat-icon>
                  </button>
                </div>
                <div class="price-product">
                  <h3>{{product?.price | number:'1.0'}} ៛ /</h3>
                  <span class="small-price">
                    {{ product?.unit_per_sale }}{{
                    {en:product?.uom?.name?.en,
                    km:product?.uom?.name?.km} | lang:language.lang}}</span>
                  <span class="discount" *ngIf="product?.discount > 0">- {{product?.discount}} %</span>
                </div>
                <div class="quantity">
                  <div class="qty">
                    <div class="btn minus" matRipple (click)="updateQTY('-',item,product)">
                      <i class="fas fa-minus"></i>
                    </div>
                    <div class="qty-num">
                      <span id="qty">{{{data:product, qty:item.qty*product.unit_per_sale } | convertUom:language.lang}}</span>
                    </div>
                    <div class="btn plus" matRipple (click)="updateQTY('+',item)">
                      <i class="fas fa-plus"></i>
                    </div>
                  </div>
                </div>
                <div class="total">
                  {{ {en:'Total', km:'សរុប :'} | lang: language.lang }}<span> {{ (product | discount) * item.qty |
                    number:'1.0'}} ៛</span>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="empty-cart" *ngIf="cartStore?.empty">
            <app-empty></app-empty>
          </div>

        </div>
        <app-loading *ngIf="cartStore.loading" class="loading"></app-loading>
      </div>
      <div class="right">
        <div class="cart">
          <h3>{{ {en:'Cart List', km:'បញ្ជីរទំនិញ'} | lang: language.lang }}</h3>
          <div class="form" [formGroup]="form">
            <div class="fill name">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>{{ {en:'Name', km:'ឈ្មោះ'} | lang: language.lang }}</mat-label>
                <input matInput formControlName="name"
                  placeholder="{{ {en:'Please Enter Your Name ...', km:'បញ្ចូលឈ្មោះទីនេះ ...'} | lang: language.lang }}">
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
            </div>
            <div class="fill phone">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>{{ {en:'Phone Number', km:'លេខទូរស័ព្ទ'} | lang: language.lang }}</mat-label>
                <input matInput formControlName="phone"
                  placeholder="{{ {en:'Please Enter Your Phone Number', km:'បញ្ចូលទូរស័ព្ទទីនេះ ...'} | lang: language.lang }}">
                <mat-icon matSuffix>local_phone</mat-icon>
              </mat-form-field>
            </div>
            <div class="fill text">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>{{ {en:'Address', km:'អាសយដ្ឋាន'} | lang: language.lang }}</mat-label>
                <textarea matInput formControlName="address"
                  placeholder="{{ {en:'Please Enter Your Address', km:'អាសយដ្ឋាន...'} | lang: language.lang }}"></textarea>
              </mat-form-field>
            </div>

            <div class="line"></div>
            <div class="price-area">
              <div class="sub-total">
                <p>{{ {en:'Price', km:'សរុបតម្លៃដើម'} | lang: language.lang }}</p>
                <p>{{cartStore.subTotal| number:'1.0'}} ៛</p>
              </div>
              <div class="price-discount">
                <p>{{ {en:'Discount', km:'បញ្ចុះតម្លៃ'} | lang: language.lang }}</p>
                <p>- {{cartStore.discount| number:'1.0'}} ៛</p>
              </div>
              <div class="last-total">
                <p>{{ {en:'Total', km:'សរុបតម្លៃទាំងអស់'} | lang: language.lang }}</p>
                <p>{{cartStore.total | number:'1.0'}} ៛</p>
              </div>
            </div>
            <button mat-flat-button class="button-order" (click)="onOrder(form.value,cartStore?.productCart)"
              [disabled]="process"><span>{{ {en:'Order', km:'បញ្ជាទិញ'} | lang: language.lang }}</span></button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>