import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { ConfirmEmailComponent } from '../confirm-email/confirm-email.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import * as firebase from 'firebase';
import { IUser } from 'src/app/interfaces/IUser';
import { WindowService } from 'src/app/services/window.service';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  hide = true;
  hide_confirm_pw = true;
  //register-form
  registerForm: FormGroup;
  name: AbstractControl;
  phone: AbstractControl;

  verifyForm: FormGroup;
  code: AbstractControl;

  loading: boolean;
  sentCode: boolean;

  windowRef: any;

  constructor(
    private dialogRef: MatDialogRef<SignUpComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authStore: AuthStore,
    private authService: AuthService,
    private win: WindowService,
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.compose([Validators.required, Validators.pattern("[0]{1}[1-9]{1}[0-9]{7,8}")])],
    });
    this.name = this.registerForm.controls['name'];
    this.phone = this.registerForm.controls['phone'];

    this.verifyForm = this.fb.group({
      code: [null, Validators.required],
    });
    this.code = this.verifyForm.controls['code'];
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  }

  onSubmit(form: any) {
    if (this.registerForm.valid) {
      this.loading = true;
      this.registerForm.disable();
      const appVerifier = this.windowRef.recaptchaVerifier;
      const { name, phone } = form;
      const phone_number = '+855' + Number(phone);
      this.authStore.uniquePhone(phone, (res) => {
        if (!res) {
          this.authService.authRef().signInWithPhoneNumber(phone_number, appVerifier)
            .then(result => {
              this.loading = false;
              this.registerForm.enable();
              this.sentCode = true;
              this.windowRef.confirmationResult = result;
            })
            .catch(error => {
              this.loading = false;
              this.registerForm.enable();
              console.log(error);
            });
        } else {
          this.loading = false;
          this.registerForm.enable();
          this.phone.setErrors({ unique: true });
        }
      })
    }
  }

  onVerify(f: any) {
    if (this.verifyForm.valid) {
      this.code.setErrors({ wrong_code: false });
      this.loading = true;
      this.verifyForm.disable();
      this.windowRef.confirmationResult
        .confirm(f.code)
        .then((result: any) => {
          const { name, phone } = this.registerForm.value;
          const data: IUser = {
            key: result.user.uid,
            name: name,
            phone: phone,
            contact_phone: phone,
            profile: null,
            created_date: new Date(),
            verify: true,
            user_type: {
              key: 2,
              text: 'Customer'
            },
            social_login: false
          }
          this.authStore.save(data, (result: boolean, docs: any) => {
            if (result) {
              this.dialogRef.close();
            } else {
              this.loading = false;
              this.verifyForm.enable();
              console.log(docs);
            }
          });
        })
        .catch((error: any) => {
          console.log(error);
          this.loading = false;
          this.verifyForm.enable();
          this.code.setErrors({ wrong_code: true });
        });
    }
  }

  signInWithFB() {
    this.authStore.FacebookAuth((res) => {
      if (res) {
        this.close();
      }
    });
  }

  poplogin() {
    this.dialog.open(SignInComponent, {
      width: "700px",
      panelClass: "dialog-login-class",
      autoFocus: false,
    });
  }
  close() {
    this.dialogRef.close();
  }
  popConfirmPw() {
    this.dialog.open(ConfirmEmailComponent, {
      width: "500px",
      panelClass: "confirm-pw-class",
      autoFocus: false,
    });
  }

}
