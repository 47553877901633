import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private db: AngularFirestore
  ) { }

  orderRef(key: string) {
    return this.db.collection('orders').doc(key);
  }

  getOrderByUser(userKey: string) {
    return this.db.collection('orders', ref => ref
      .orderBy('created_date', 'desc')
      .where("created_by.key", "==", userKey)
    );
  }

  getLastOrderByOrderNo() {
    return this.db.collection('orders', ref => ref
      .orderBy('order_no', 'desc')
      .limit(1)
    );
  }

}
