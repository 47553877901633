import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private db: AngularFirestore
  ) { }

  createKey() {
    return this.db.createId();
  }

  getContact() {
    return this.db.collection('contact');
  }
  getAbout() {
    return this.db.collection('about');
  }
  getProduct(option: 'all' | 'next' | 'back', limit: number, firstOrLastItemDate?: Date, categoryKey?: string, keyword?: string) {
    let from_date = new Date();
    from_date.setHours(23, 59, 59, 999);
    return this.db.collection('products', (ref) => {
      let con = ref
        .where("status.key", "==", 1)
        .orderBy("created_date", "desc");
      if (keyword) {
        con = con.where("keyword", "array-contains", keyword);
      }
      if (categoryKey) {
        con = con.where("category", "array-contains", categoryKey);
      }
      switch (option) {
        case 'all':
          con = con.startAt(from_date)
            .limit(limit + 1);
          break;
        case 'next':
          con = con.startAfter(firstOrLastItemDate)
            .limit(limit + 1);
          break;
        case 'back':
          con = con.endBefore(firstOrLastItemDate)
            .limitToLast(limit);
          break;
      }
      return con;
    });
  }
  getDetail(key: any) {
    return this.db.collection('products').doc(key);
  }
  productRelate(cateKey: any) {
    return this.db.collection('products', ref => {
      let con = ref
      if (cateKey) {
        return con
          .where('category', 'array-contains-any', cateKey)
          .where('status.key', '==', 1)
          .orderBy("created_date", "desc")
          .limit(5);
      }
      return con
        .orderBy("created_date", "desc")
        .limit(5)
    });
  }
  user() {
    return this.db.collection('users');
  }
  userRef() {
    return this.db.collection('users');
  }
  getCategory() {
    return this.db.collection('categories', ref => {
      let con = ref.orderBy('ordering', 'asc')
        .where('status.key', '==', 1)
        .where('parent_key', '==', null);
      return con;
    })
  }
  getSubCategory(cateKey: any) {
    return this.db.collection('categories', ref => {
      let con = ref.orderBy('ordering', 'asc')
        .where('parent_key', '==', cateKey)
        .where('status.key', '==', 1)
      return con;
    })
  }


}
