<div class="account">
  <div class="has-fixed"></div>
  <div class="content fixed">
    <div class="container-account">
      <div class="button-profile">
        <div class="item" [routerLink]="['profile']" [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active">
          <p>{{ {en:'Profile', km:'ពត៌មានផ្ទាល់ខ្លួន'} | lang: language.lang }}</p>
        </div>
        <div class="item" [routerLink]="['order-list']" routerLinkActive="active">
          <p>{{ {en:'Order List', km:'តារាងទិញទំនិញ'} | lang: language.lang }}</p>
        </div>
      </div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
