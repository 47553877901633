<div class="header">
  <div class="header-top">
    <div class="content fixed">
      <div class="container-header-top">
        <div class="user-header">
          <div
            class="account-name"
            (click)="goLink(['account'])"
            *ngIf="authStore.currentUser"
          >
            <div class="pic-profile">
              <i class="fas fa-user-circle"></i>
            </div>
            <div class="name-profile">
              <span>{{ authStore.currentUser?.name }}</span>
            </div>
          </div>
          <div
            class="account-name"
            (click)="poplogin()"
            *ngIf="!authStore.currentUser"
          >
            <div class="pic-profile">
              <i class="fas fa-user-circle"></i>
            </div>
            <div class="name-profile">
              <span>{{
                { en: "Login", km: "ចូលគណនី" } | lang : language.lang
              }}</span>
            </div>
          </div>
        </div>
        <div class="phone-number">
          <i class="fas fa-phone-alt"></i>
          <span>{{
            { en: "Phone Number : ", km: "លេខទំនាក់ទំនង : " }
              | lang : language.lang
          }}</span>
          <span (click)="call('tel:' + contactStore?.data?.phone)">
            {{ contactStore?.data?.phone }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="header-menu" #stickyMenu [class.sticky]="sticky">
    <div class="content fixed">
      <div class="container-header-menu">
        <div class="menu-left">
          <button mat-button (click)="toggleSidebar()" class="btn-show-sidebar">
            <mat-icon>menu</mat-icon>
          </button>
          <div class="image" (click)="toHome([''])">
            <img
              src="./assets/images/logo/Funan Farm Logo-04.png"
              alt="Funan Farm"
            />
            <img
              class="mobile-img"
              src="./assets/images/logo/Funan Farm Logo-01.png"
              alt="Funan Farm"
            />
          </div>
          <div class="menu-list">
            <ul>
              <a
                href=""
                [routerLink]="['']"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
              >
                <li>
                  {{
                    { en: "All Product", km: "មុខទំនិញទាំងអស់" }
                      | lang : language.lang
                  }}
                </li>
              </a>
              <a href="" [routerLink]="['category']" routerLinkActive="active">
                <li>
                  {{
                    { en: "Category", km: "ប្រភេទទំនិញ" } | lang : language.lang
                  }}
                </li>
              </a>
              <a href="" [routerLink]="['about']" routerLinkActive="active">
                <li>
                  {{ { en: "About Us", km: "អំពីយើង" } | lang : language.lang }}
                </li>
              </a>
              <a href="" [routerLink]="['contact']" routerLinkActive="active">
                <li>
                  {{
                    { en: "Contact Us", km: "ទំនាក់ទំនង" }
                      | lang : language.lang
                  }}
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div class="menu-right">
          <div class="search">
            <ng-container>
              <div class="input">
                <input
                  type="text"
                  placeholder="{{
                    { en: 'Search...', km: 'ស្វែងរក...' } | lang : language.lang
                  }}"
                  #input
                  (keyup.enter)="onSearch(input.value)"
                />
                <mat-icon (click)="onSearch(input.value)">search</mat-icon>
              </div>
            </ng-container>
          </div>
          <!-- <div class="icon-right">
            <div class="icon" (click)="goLink(['cart-list'])">
              <i
                class="fas fa-cart-arrow-down"
                [matBadge]="cartItemCount"
                matBadgeColor="warn"
                [matBadgeHidden]="!cartItemCount"
              ></i>
            </div>
          </div> -->
          <!-- <div class="user" (click)="poplogin()" *ngIf="!authStore.currentUser">
            <span>{{
              { en: "Login", km: "ចូលគណនី" } | lang : language.lang
            }}</span>
          </div> -->
          <div
            class="account-name"
            [matMenuTriggerFor]="menu"
            *ngIf="authStore.currentUser"
          >
            <div class="pic-profile">
              <div class="image">
                <img
                  [src]="authStore.currentUser?.profile"
                  #profile
                  (error)="
                    profile.src =
                      './assets/images/logo/logo-removebg-preview (1).png'
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="name-profile">
              <span>{{ authStore.currentUser?.name }}</span>
            </div>
            <div class="sign-out" (click)="signOut()">
              <i class="fas fa-sign-out-alt"></i>
            </div>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="goLink(['account'])">
              <mat-icon>person</mat-icon>
              <span>Account</span>
            </button>
            <button mat-menu-item (click)="signOut()">
              <mat-icon>logout</mat-icon>
              <span>Sign Out</span>
            </button>
          </mat-menu>
          <div class="change-language" [matMenuTriggerFor]="langMenu">
            <img
              [src]="
                language.lang == 'km'
                  ? './assets/images/photo/cambodia.svg'
                  : './assets/images/photo/united-states.svg'
              "
              alt=""
            />
            <span>
              <i class="fas fa-fw fa-angle-down"></i>
            </span>
            <!-- <div class="sub-language">
              <div class="sub-nav-list">
                <div class="sub-nav-item" (click)="ChangeLanguage('km')">
                  <img src="./assets/images/photo/cambodia.svg" alt="">
                  <span>ខ្មែរ</span>
                </div>
                <div class="sub-nav-item" (click)="ChangeLanguage('en')">
                  <img src="./assets/images/photo/united-states.svg" alt="">
                  <span>English</span>
                </div>
              </div>
            </div> -->
          </div>
          <mat-menu #langMenu="matMenu" class="lang-menu-list">
            <button mat-menu-item (click)="ChangeLanguage('km')">
              <img src="./assets/images/photo/cambodia.svg" alt="" />
              <span>ខ្មែរ</span>
            </button>
            <button mat-menu-item (click)="ChangeLanguage('en')">
              <img src="./assets/images/photo/united-states.svg" alt="" />
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="page-body" (click)="toggleSidebar()"></div>

    <div class="page-wrapper">
      <div class="menu-toggle-header">
        <div class="left">
          <div class="image">
            <img src="./assets/images/logo/Funan Farm Logo-04.png" alt="" />
          </div>
          <span [routerLink]="['/']" (click)="toggleSidebar()"></span>
        </div>
        <mat-icon (click)="toggleSidebar()">close</mat-icon>
      </div>
      <div class="search">
        <ng-container>
          <div class="input">
            <input
              type="text"
              #mInput
              (keyup.enter)="onSearch(mInput.value)"
              placeholder="{{
                { en: 'Search...', km: 'ស្វែងរក...' } | lang : language.lang
              }}"
            />
            <mat-icon (click)="onSearch(mInput.value)">search</mat-icon>
          </div>
        </ng-container>
      </div>
      <mat-list role="list" (click)="toggleSidebar()">
        <mat-list-item>
          <button
            mat-button
            [routerLink]="['']"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="active"
          >
            <mat-icon>home</mat-icon>
            {{
              { en: "All Product", km: "មុខទំនិញទាំងអស់" }
                | lang : language.lang
            }}
          </button>
          <button
            mat-button
            [routerLink]="['category']"
            routerLinkActive="active"
          >
            <mat-icon>category</mat-icon
            >{{ { en: "Category", km: "ប្រភេទទំនិញ" } | lang : language.lang }}
          </button>
          <button mat-button [routerLink]="['about']" routerLinkActive="active">
            <mat-icon>info</mat-icon
            >{{ { en: "About Us", km: "អំពីយើង" } | lang : language.lang }}
          </button>
          <button
            mat-button
            [routerLink]="['contact']"
            routerLinkActive="active"
          >
            <mat-icon>call</mat-icon
            >{{ { en: "Contact Us", km: "ទំនាក់ទំនង" } | lang : language.lang }}
          </button>
          <!-- <button mat-button>
            <div class="user" (click)=" poplogin()" *ngIf="!authStore.currentUser">
              <i class="fas fa-user-circle"></i>
              <span>ចូលគណនី</span>
            </div>
            <div class="account-name" *ngIf="authStore.currentUser">
              <div class="pic-profile" (click)="goLink(['account'])">
                <div class="image">
                  <img [src]="authStore.currentUser?.profile" #profile
                    (error)="profile.src='./assets/images/logo/logo-removebg-preview (1).png'" alt="">
                </div>
              </div>
              <div class="name-profile" (click)="goLink(['account'])">
                <span>{{authStore.currentUser?.name}}</span>
              </div>
            </div>
            <div class="exit-account" *ngIf="authStore.currentUser">
              <div class="sign-out" (click)="signOut()"><i class="fas fa-sign-out-alt"></i>ចាកចេញពីគណនី</div>
            </div>
          </button> -->
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
