import { MappingService } from 'src/app/services/mapping.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './../auth/auth.service';
import { ConvertService } from './../services/convert.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { observable, action } from 'mobx';
import { Injectable } from '@angular/core';
import { ICart } from '../interfaces/ICart';
import { DataService } from '../services/data.service';
import { CartService } from '../services/data/cart.service';

@Injectable()
export class CartStore {
  @observable loading: boolean;
  @observable cart: any[];
  @observable wishlist: any[];
  @observable empty: boolean;
  @observable wishlistempty: boolean;
  @observable emptyOrder: boolean;
  @observable cartList: any;
  count: number = 0;
  countItem: number = 0;
  subTotal: number = 0;
  total: number = 0;
  discount: number = 0;
  productCart: any[];
  constructor(
    private cartService: CartService,
    private ds: DataService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private dialog: MatDialog,
  ) {
    this.countCart();
  }

  @action
  fetchCountCart(userKey, callback: any) {
    this.cartService.userCart(userKey).valueChanges().subscribe(cart => {
      this.cartList = cart;
      callback(cart.length);
    });
  }

  @action
  fetchCart(userKey, callback: any) {
    this.loading = true;
    this.empty = false;
    this.cartService.userCart(userKey).valueChanges().subscribe(cart => {
      this.cartList = cart;
      callback(cart);
      this.loading = false;
      this.empty = cart.length === 0;
      this.totalCart(cart);
    });
  }

  @action
  totalCart(cart: any) {
    const productCart = [];
    let count = 0;
    this.total = 0;
    this.subTotal = 0;
    this.discount = 0;
    cart.map(async (item) => {
      const docs = await this.cartService.productRef(item.product_key).get().toPromise();
      const product = docs.data();
      this.subTotal += (product.price * item.qty);
      const discount = product.price * (product.discount / 100);
      this.discount += discount * item.qty;
      this.total += (product.price - discount) * item.qty;
      productCart.push({
        cart: item,
        product: docs.data()
      });
      if (count == cart.length - 1) {
        this.productCart = productCart;
      }
      count++;
    });
  }

  @action
  countCart() {
    this.loading = true;
    this.auth.canActiveRef().subscribe(user => {
      if (user) {
        this.cartService.userCart(user.uid).valueChanges().subscribe(cart => {
          this.count = cart.length;
          this.loading = false;
        });
      }
    });
  }

  @action
  updateQty(userKey: string, item: any, qty: number) {
    this.cartService.addCart(userKey).doc(item.key).update({
      qty: item.qty + qty
    });
  }

  @action
  addCart(data: ICart, userKey: string, callback) {
    if (data) {
      this.checkDuplicateCart(userKey, data.product_key).then((res: any) => {
        if (res) {
          this.cartService.addCart(userKey).doc(res.key).update({
            qty: data.qty + res.qty,
          }).then((docs) => {
            callback(true);
          }).catch((error) => {
            callback(false);
          });
        } else {
          this.cartService.addCart(userKey).doc(data.key).set(data).then((docs) => {
            callback(true);
          }).catch((error) => {
            callback(false);
          });
        }
      })
    }
  }

  async checkDuplicateCart(userKey: string, productKey: string) {
    const doc = await this.cartService.getCartProductByUser(userKey, productKey).get().toPromise();
    return MappingService.pushToArray(doc)[0];
  }

  @action
  removeCart(userKey: string, itemKey: string, callback: any) {
    this.cartService.userCart(userKey).doc(itemKey).delete().then(() => {
      this.loading = false;
      this.total = 0;
      this.discount = 0;
      callback(true);
    }).catch(error => {
      this.loading = false;
      callback(false);
    });
  }

}
