import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SafePipe } from './pipes/safe.pipe';
import { HeaderComponent } from './shared/header/header.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { AboutComponent } from './pages/about/about.component';
import { DetailComponent } from './pages/detail/detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryComponent } from './pages/category/category.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ProductsComponent } from './pages/products/products.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { APP_STORES } from './stores/app.store';
import { environment } from 'src/environments/environment';
import { EmptyComponent } from './components/empty/empty.component';
import { EmbedLinkPipe } from './pipes/embed-link.pipe';
import { SubCategoryComponent } from './pages/sub-category/sub-category.component';
import { SearchListComponent } from './pages/search-list/search-list.component';
import { CartListComponent } from './pages/cart-list/cart-list.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { AccountComponent } from './pages/account/account.component';
import { AuthGuard } from './auth/auth.guard';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MessageComponent } from './components/message/message.component';
import { DocRefPipe } from './pipes/doc-ref.pipe';
import { DiscountPipe } from './pipes/discount.pipe';
import { BuyNowComponent } from './components/buy-now/buy-now.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ConvertUomPipe } from './pipes/convert-uom.pipe';
import { LangPipe } from './pipes/lang.pipe';
import { PopupDetailComponent } from './components/popup-detail/popup-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoadingComponent,
    SafePipe,
    HeaderComponent,
    LayoutComponent,
    FooterComponent,
    AboutComponent,
    DetailComponent,
    ContactComponent,
    CategoryComponent,
    SignInComponent,
    SignUpComponent,
    ProductsComponent,
    ForgetPasswordComponent,
    ConfirmEmailComponent,
    EmptyComponent,
    EmbedLinkPipe,
    SubCategoryComponent,
    SearchListComponent,
    CartListComponent,
    ProfileComponent,
    OrderListComponent,
    AccountComponent,
    ConfirmDialogComponent,
    MessageComponent,
    DocRefPipe,
    DiscountPipe,
    BuyNowComponent,
    ConvertUomPipe,
    LangPipe,
    PopupDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DragScrollModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatMenuModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase, 'FunanFarm'),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule // storage
  ],
  providers: [
    APP_STORES,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
