import { CategoryStore } from 'src/app/stores/category.store';
import { Component, OnInit } from '@angular/core';
import { ContactStore } from 'src/app/stores/contact.store';
import { ProductStore } from 'src/app/stores/product.store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BuyNowComponent } from 'src/app/components/buy-now/buy-now.component';
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { AuthStore } from 'src/app/stores/auth.store';
import { LanguageStore } from 'src/app/stores/language.store';
import { IMessage, IMessagePopup } from 'src/app/interfaces/IMessage';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ICart } from 'src/app/interfaces/ICart';
import { MessageComponent } from 'src/app/components/message/message.component';
import { DataService } from 'src/app/services/data.service';
import { CartStore } from 'src/app/stores/cart.store';
import { PopupDetailComponent } from 'src/app/components/popup-detail/popup-detail.component';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})

export class SubCategoryComponent implements OnInit {
  public grid: boolean = true;
  data: any;
  menuActive: string;
  mainCateKey: string;
  constructor(
    private router: Router,
    public productStore: ProductStore,
    public contactStore: ContactStore,
    public categoryStore: CategoryStore,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private auth: AuthStore,
    public language: LanguageStore,
    private ds: DataService,
    private cartStore: CartStore
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.categoryStore.fetchSubCategory(param.cateKey);
      if (param.subKey) {
        this.menuActive = param.subKey;
        this.productStore.fetchProduct('all', null, this.menuActive);
      } else {
        this.productStore.fetchProduct('all', null, param.cateKey);
      }
      this.mainCateKey = param.cateKey;
    });
  }

  fetchMore(opt: 'next' | 'back', date: string) {
    if (this.menuActive) {
      this.productStore.fetchProduct(opt, new Date(date), this.menuActive);
    }
    else {
      this.productStore.fetchProduct(opt, new Date(date), this.mainCateKey);
    }

  }

  // toDetail(id: string) {
  //   this.router.navigate(["detail", id]);
  // }
  onClick(str: boolean) {
    this.grid = str;
  }
  call(link: string, page?: boolean) {
    let social = window.open(link, page ? "_blank" : '_self');
  }
  social(link: string, page?: boolean) {
    let social = window.open(link, page ? "_blank" : '_self');
  }
  onClickCategory(key?: string) {
    if (key) {
      this.router.navigate(["category", this.mainCateKey, key])
    } else
      this.router.navigate(["category", this.mainCateKey]);
  }

  popBuyNow(item: any) {
    this.auth.checkLogin((res, user) => {
      if (res != true) {
        this.dialog.open(SignInComponent, {
          width: "700px",
          panelClass: "dialog-login-class",
          autoFocus: false,
        });
      }
      else {
        this.dialog.open(BuyNowComponent, {
          width: "350px",
          panelClass: "dialog-buy-now",
          autoFocus: false,
          data: item
        });
      }
    })
  }

  addToCart(item: any) {
    this.auth.checkLogin((res, user) => {
      if (res != true) {
        this.dialog.open(SignInComponent, {
          width: "700px",
          panelClass: "dialog-login-class",
          autoFocus: false,
        });
      }
      else {
        const data: IMessage = {
          title: {
            km: "បន្ថែមទៅកន្ត្រក",
            en: "Add To Cart"
          },
          msg: {
            km: `តើអ្នកចង់បន្ថែម ទៅកន្ត្រកមែនឬទេ?`,
            en: `Are you want to add to cart?`
          },
          textButton: {
            km: "បន្ថែមទៅកន្ត្រក",
            en: "Add to Cart"
          },
        };
        const result = this.dialog.open(ConfirmDialogComponent, {
          data: data,
          panelClass: 'confirm-dialog',
          width: '300px',
          autoFocus: false
        });
        result.afterClosed().subscribe((res) => {
          if (res) {
            const order: ICart = {
              key: this.ds.createKey(),
              product_key: item.key,
              qty: item.min_qty / item.unit_per_sale,
              created_date: new Date(),
            }

            this.cartStore.addCart(order, user.uid, (res) => {
              // if (res) {
              //   this.dialogMessage({
              //     title: {
              //       km: "ជោគជ័យ",
              //       en: "Success"
              //     },
              //     msg: {
              //       km: "ប្រតិបត្តិការណ៍ទទួលបានជោគជួយ។",
              //       en: "Process is Success"
              //     },
              //     duration: 3
              //   });
              // } else {
              //   this.dialogMessage({
              //     title: {
              //       km: "បរាជ័យ",
              //       en: "Fault"
              //     },
              //     msg: {
              //       km: "ប្រតិបត្តិការណ៍បានបរាជ័យ សូមព្យាយាមម្ដងទៀត។",
              //       en: "Process Fault, Please Try Again"
              //     },
              //     duration: 3
              //   });
              // }
            });
          }
        });
      }
    });

  }

  dialogMessage(option: IMessagePopup) {
    this.dialog.open(MessageComponent, {
      data: option,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
  }

  toDetail(item: any) {
    console.log(item)
    this.dialog.open(PopupDetailComponent, {
      data: item,
      width: "1200px",
      panelClass: "dialog-detail-class",
      autoFocus: false,
    });
  }

}
