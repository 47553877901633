import { ContactStore } from './../../stores/contact.store';
import { HostListener } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { AuthStore } from 'src/app/stores/auth.store';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { IMessage } from 'src/app/interfaces/IMessage';
import { LanguageStore } from 'src/app/stores/language.store';
import { CartStore } from 'src/app/stores/cart.store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  sticky: boolean = false;
  elementPosition: any;
  home: any;
  cartItemCount: number;


  @ViewChild('stickyMenu', { static: false }) menuElement: ElementRef;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    public authStore: AuthStore,
    public contactStore: ContactStore,
    public language: LanguageStore,
    public cartStore: CartStore

  ) { }

  ngOnInit(): void {
    this.contactStore.fetchContact();
    this.authStore.checkAuth((user) => {
      if (user) {
        this.cartStore.fetchCountCart(user.key, (cart) => {
          this.cartItemCount = cart;
        });
      }
    });
  }

  toHome(path: any[]) {
    this.router.navigate(path);
  }

  @HostListener('window:scroll', [''])
  @HostListener('window:scroll')
  handleScroll() {
    this.elementPosition = document.querySelector<Element>(".header-top")?.clientHeight;
    let header = document.querySelector<Element>(".header-menu")?.clientHeight;
    this.home = document.querySelector<Element>('.has-fixed');
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.elementPosition) {
      this.sticky = true;
      if (this.home) {
        this.home.style.paddingTop = `${header}px`;
      }
    }
    else {
      if (this.dialog.openDialogs.length <= 0) {
        this.sticky = false;
        if (this.home) {
          this.home.style.paddingTop = `0px`;
        }
      }
    }
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  toggleSidebar() {
    document.getElementsByTagName("body")[0].classList.toggle("active");
    let body = document.getElementsByClassName("page-body")[0];
    body.classList.toggle("active");
    let page = document.getElementsByClassName("page-wrapper")[0];
    page.classList.toggle("active");
  }

  @HostListener('window:resize')
  onResize() {
    let w = window.matchMedia("(min-width: 480px)");
    if (w.matches) {
      document.getElementsByTagName("body")[0].classList.remove("active");
      let body = document.getElementsByClassName("page-body")[0];
      body.classList.remove("active");
      let page = document.getElementsByClassName("page-wrapper")[0];
      page.classList.remove("active");
    }
  }

  goLink(path: any[]) {
    this.router.navigate(path);
  }

  call(link: string, page?: boolean) {
    let social = window.open(link, page ? "_blank" : '_self');
  }

  poplogin() {
    this.dialog.open(SignInComponent, {
      width: "700px",
      panelClass: "dialog-login-class",
      autoFocus: false,
    });
  }
  onSearch(input: string) {
    if (input == "" || input == null) {
      input = "all";
    }
    this.router.navigate(['search-product', input]);
  }

  signOut() {
    const data: IMessage = {
      title: {
        km: "ចេញពីគណនី",
        en: "Sign Out"
      },
      msg: {
        km: "តើអ្នកពិតជាចង់ចេញពីចេញពីគណនីមែនឬទេ?",
        en: "Are you sure to Sign Out?"
      },
      textButton: {
        km: "ចេញពីគណនី",
        en: "Sign Out"
      },
    };
    const result = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
    result.afterClosed().subscribe((res) => {
      if (res) {
        this.authStore.signOut((res) => {
          if (res) {
            this.goLink(['']);
          }
        });
      }
    })

  }
  ChangeLanguage(lang: string) {
    this.language.changeLang(lang);
  }

}
