import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUom'
})
export class ConvertUomPipe implements PipeTransform {

  transform(product: any, lang: string): string {
    let qtyString = "";
    const { data, qty } = product;
    if (data.uom) {
      if (data.uom.uom_parent) {
        if (data.uom.uom_parent) {
          if (Math.floor(qty / data.uom.uom_parent.qty) > 0)
            qtyString = `${Math.floor((qty / data.uom.uom_parent.qty))} ${data.uom.uom_parent.name[lang]}`;
        }
        if (qty % data.uom.uom_parent.qty > 0) {
          if (qtyString != "") {
            qtyString += ", ";
          }
          qtyString += `${qty % data.uom.uom_parent.qty} ${data.uom.name[lang]}`;
        }
      } else {
        qtyString = `${qty} ${data.uom.name[lang]}`;
      }
    } else {
      qtyString = String(qty);
    }
    return qtyString;
  }

}
