import { Component } from '@angular/core';
import { LanguageStore } from './stores/language.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FunanFarm';
  constructor(public lang: LanguageStore) { }
  ngOnInit() {
    this.lang.getLang();
  }
}
