import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, arg?: number): number {
    if (value) {
      if (arg == 1) {
        return (value.price * (value.discount / 100));
      } else {
        return (value.price - (value.price * (value.discount / 100)));
      }
    }

  }

}
