import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private db: AngularFirestore
  ) { }

  userCart(userKey: any) {
    return this.db.collection('users').doc(userKey).collection('carts', ref => ref.orderBy('created_date', 'desc'));
  }

  productRef(id: string) {
    return this.db.collection('products').doc<any>(id);
  }

  userOrder(userKey: any) {
    return this.db.collection('customer').doc(userKey).collection('orders', ref => ref.orderBy('order_date_key', 'desc'));
  }
  userCartRef(userKey: any, key: any) {
    return this.db.collection('customer').doc(userKey).collection('cart', ref => ref.where('itemKey', '==', key));
  }

  addCart(userKey: string) {
    return this.db.collection("users").doc(userKey).collection("carts");
  }

  getCartProductByUser(userKey: string, productKey: string) {
    return this.db.collection("users").doc(userKey).collection("carts", res => {
      let con = res.where('product_key', '==', productKey);
      return con;
    });
  }

  getCart(userKey: string) {
    return this.db.collection('users').doc(userKey).collection('carts', ref => ref
      .orderBy('page_key', 'desc')
    );
  }

  orderRef(key: string) {
    return this.db.collection('orders').doc(key);
  }

  getOrderByUser(userKey: string) {
    return this.db.collection('orders', ref => ref
      .orderBy('page_key', 'desc')
      .where("created_by.key", "==", userKey)
    );
  }
}
