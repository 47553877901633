<div class="forget-pw">
  <div class="title">
    <h3>សូមបញ្ចូលលេខសំងាត់ដែលបានផ្ញើរទីក្នុងអ៉ីម៉ែលរបស់អ្នក!</h3>
  </div>
  <div class="image">
    <img src="./assets/images/logo/logo-removebg-preview (1).png" alt="">
  </div>
  <div class="box">
    <input type="text">
    <input type="text">
    <input type="text">
    <input type="text">
    <input type="text">
    <input type="text">
  </div>

  <button mat-flat-button class="button-forget"><span>ផ្ញើតំណកំណត់ឡើងវិញ</span></button>
</div>
