import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { observable, action } from 'mobx-angular';
import { MappingService } from '../services/mapping.service';
import * as _ from 'lodash';

@Injectable()
export class CategoryStore {

  @observable data: any;
  @observable loading: boolean;

  dataCategory: any[];
  dataSubCategory: any[];

  constructor(
    private ds: DataService
  ) { }
  @action
  async fetchCategory() {
    this.loading = true;
    const docs = await this.ds.getCategory().get().toPromise();
    const data = MappingService.pushToArray(docs);
    this.data = _.orderBy(data, ['name'], ['asc']);
    if (data) {
      this.loading = false;
    }
  }

  @action
  async fetchMainCategory() {
    const docs = await this.ds.getCategory().get().toPromise();
    const data = MappingService.pushToArray(docs);
    this.dataCategory = data;
  }

  @action
  async fetchSubCategory(cateKey: any) {
    const docs = await this.ds.getSubCategory(cateKey).get().toPromise();
    const data = MappingService.pushToArray(docs);
    this.dataSubCategory = data;
  }
}
