<ng-container *ngIf="!productStore.loading">
  <div class="home">
    <div class="has-fixed"></div>
    <div class="content fixed">
      <div class="product">
        <div class="main-title">
          <div class="container">
            <div class="text">
              <span
                >{{ { en: "Search", km: "ស្វែងរក" } | lang : language.lang }}
                <i class="fas fa-chevron-right"></i
              ></span>
            </div>
            <div class="grid">
              <button
                mat-icon-button
                class="grid-bar {{ grid ? 'active' : '' }}"
              >
                <mat-icon class="mat-18" (click)="onClick(true)"
                  >border_all</mat-icon
                >
              </button>
              <button
                mat-icon-button
                class="grid-bar {{ !grid ? 'active' : '' }}"
              >
                <mat-icon class="mat-18" (click)="onClick(false)"
                  >menu</mat-icon
                >
              </button>
            </div>
          </div>
          <div class="underline"></div>
        </div>
        <!-- <div class="title-home">
          <button [class.active]="!menuActive" (click)="onClickCategory()"><span>ផលិតផលទាំងអស់</span></button>
          <button class="sub-button" *ngFor="let item of categoryStore?.dataCategory"
            [class.active]="menuActive == item?.key"
            (click)="onClickCategory(item?.key)"><span>{{item?.name}}</span></button>
        </div> -->
        <app-empty *ngIf="productStore?.empty"></app-empty>
        <div class="product-list" *ngIf="!productStore?.empty">
          <div
            class="item-product {{ !grid ? 'grid' : '' }}"
            *ngFor="let item of productStore?.data"
          >
            <div class="image-left" (click)="toDetail(item)">
              <div class="image">
                <img [src]="item?.thumbnail" alt="" />
                <div class="sub-img">
                  <img [src]="item?.thumbnail" alt="" />
                </div>
              </div>
            </div>
            <div class="text">
              <div class="title" (click)="toDetail(item)">
                <h3>
                  {{
                    { en: item?.name?.en, km: item?.name?.km }
                      | lang : language.lang
                  }}
                </h3>
              </div>
              <!-- <div class="des" (click)="toDetail(item?.key)">
                <p>{{item?.description}}</p>
              </div> -->
              <!-- <div class="sell-min-qty" (click)="toDetail(item)" *ngIf="item?.min_qty > 1">
                <p>{{ {en:'*Selling from '+item.min_qty+item.uom.name?.en+' up',
                  km:'*លក់ចាប់ពី '+item.min_qty+item.uom.name?.km+' ឡើងទៅ'} | lang:
                  language.lang }}</p>
              </div> -->
              <!-- <div class="price" (click)="toDetail(item)">
                <h3>
                  {{
                    (item.min_qty / item.unit_per_sale) * item.price
                      | number : "1.0"
                  }}
                  ៛ /
                </h3>
                <span class="small-price">
                  {{ item.min_qty
                  }}{{
                    { en: item?.uom?.name?.en, km: item?.uom?.name?.km }
                      | lang : language.lang
                  }}</span
                >
                <div class="discount" *ngIf="data?.discount > 0">
                  <h3>- {{ data.discount }} %</h3>
                </div>
              </div> -->
              <div class="button">
                <!-- <button (click)="addToCart(item)">
                  {{
                    { en: "Buy Now", km: "ទិញឥឡូវនេះ" } | lang : language.lang
                  }}
                </button> -->
                <div class="call">
                  <button class="facebook" (click)="goLink(['cart-list'])">
                    <i class="fas fa-cart-plus"></i>
                  </button>
                  <button class="call" (click)="call('tel:')">
                    <i class="fas fa-phone-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="pagi-btn"
          *ngIf="!productStore.disabledBack || !productStore.disabledNext"
        >
          <div class="pagi-content">
            <button
              mat-flat-button
              (click)="gotoTop()"
              (click)="fetchMore('back', productStore.first_item_date)"
              [disabled]="productStore.disabledBack"
            >
              {{
                { en: "Preview", km: "មើលត្រលប់ក្រោយ" } | lang : language.lang
              }}
            </button>
            <button
              mat-flat-button
              (click)="gotoTop()"
              (click)="fetchMore('next', productStore.last_item_date)"
              [disabled]="productStore.disabledNext"
            >
              {{ { en: "Next", km: "មើលបន្ទាប់" } | lang : language.lang }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="productStore.loading" class="loading"></app-loading>
