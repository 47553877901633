import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMessage, IMessagePopup } from 'src/app/interfaces/IMessage';
import { IOrder } from 'src/app/interfaces/IOrder';
import { DataService } from 'src/app/services/data.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { LanguageStore } from 'src/app/stores/language.store';
import { OrderStore } from 'src/app/stores/order.store';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MessageComponent } from '../message/message.component';

@Component({
  selector: 'app-buy-now',
  templateUrl: './buy-now.component.html',
  styleUrls: ['./buy-now.component.scss']
})
export class BuyNowComponent implements OnInit {

  form: FormGroup;

  user: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ds: DataService,
    private dialog: MatDialog,
    private orderStore: OrderStore,
    private dialogRef: MatDialogRef<BuyNowComponent>,
    private authStore: AuthStore,
    public language: LanguageStore
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.compose([Validators.required, Validators.pattern("[0]{1}[1-9]{1}[0-9]{7,8}")])],
      address: [null, Validators.required]
    });
    this.authStore.checkAuth((user) => {
      this.user = user;
      this.form.patchValue({
        name: user.name,
        phone: user.contact_phone,
        address: user.address
      })
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const data: IMessage = {
        title: {
          km: "ដាក់ការបញ្ជាទិញ",
          en: "Place an order"
        },
        msg: {
          km: "តើអ្នកចង់ដាក់ការបញ្ជាទិញឥលូវមែនឬទេ?",
          en: "Do you want to place an order now?"
        },
        textButton: {
          km: "បញ្ជាទិញ",
          en: "Order Now"
        },
      };
      const result = this.dialog.open(ConfirmDialogComponent, {
        data: data,
        panelClass: 'confirm-dialog',
        width: '300px',
        autoFocus: false
      });
      result.afterClosed().subscribe((res) => {
        this.orderStore.getLastOrder((lastOrder) => {
          let lastOrderNo = 1;
          if (lastOrder.length > 0) {
            lastOrderNo = Number(lastOrder[0].order_no.replace( /^\D+/g, '')) + 1;
          }
          if (res) {
            const { name, phone, address } = this.form.value;
            let total = 0;
            let discount = 0;
            const qty = this.data.qty || (this.data.min_qty / this.data.unit_per_sale) || 1;
            const totalPerUnit = this.data.price - (this.data.price * (this.data.discount / 100));
            total += (totalPerUnit * qty);
            const discountPerUnit = this.data.price * (this.data.discount / 100);
            discount += (discountPerUnit * qty);
            const item: IOrder = {
              key: this.ds.createKey(),
              order_no: `FF0000${lastOrderNo}`,
              product: [{
                qty: this.data.min_qty / this.data.unit_per_sale,
                key: this.data.key,
                name: this.data.name,
                description: this.data.description,
                thumbnail: this.data.thumbnail,
                price: Number(this.data.price),
                discount: this.data.discount,
                uom: this.data.uom,
                min_qty: this.data.min_qty,
                unit_per_sale: this.data.unit_per_sale
              }],
              name: name,
              phone: phone,
              address: address,
              created_by: this.user,
              created_date: new Date,
              status: {
                key: 1,
                text: "Pending"
              },
              total_discount: discount,
              total_price: total
            };
            this.orderStore.save(item, (res) => {
              if (res) {
                this.dialogRef.close();
                this.dialogMessage({
                  title: {
                    km: "ជោគជ័យ",
                    en: "Success"
                  },
                  msg: {
                    km: "ប្រតិបត្តិការណ៍ទទួលបានជោគជួយ។",
                    en: "Success"
                  },
                  duration: 3
                });
              } else {
                this.dialogMessage({
                  title: {
                    km: "បរាជ័យ",
                    en: "Fault"
                  },
                  msg: {
                    km: "ប្រតិបត្តិការណ៍បានបរាជ័យ សូមព្យាយាមម្ដងទៀត។",
                    en: "Process Fault, Please Try Again"
                  },
                  duration: 3
                });
              }
            });
          }
        });
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  dialogMessage(option: IMessagePopup) {
    this.dialog.open(MessageComponent, {
      data: option,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
  }

}
