import { LanguageStore } from './language.store';
import { ProductStore } from './product.store';
import { AboutStore } from './about.store';
import { CategoryStore } from './category.store';
import { ContactStore } from './contact.store';
import { AuthStore } from './auth.store';
import { CartStore } from './cart.store';
import { OrderStore } from './order.store';

export const APP_STORES = [
  AuthStore,
  CategoryStore,
  ContactStore,
  AboutStore,
  ProductStore,
  CartStore,
  OrderStore,
  LanguageStore
]
