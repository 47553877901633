<ng-container *ngIf="!aboutStore.loading">
  <div class="about">
    <div class="has-fixed"></div>
    <div class="content fixed">
      <div class="container-about">
        <div class="image">
          <img [src]="aboutStore?.data?.thumbnail" alt="" />
        </div>
        <div class="title">
          <h3>
            {{
              { en: aboutStore?.data?.name?.en, km: aboutStore?.data?.name?.km }
                | lang : language.lang
            }}
          </h3>
        </div>
        <!-- <div class="des">
          <p>
            {{
              {
                en: aboutStore?.data?.description?.en,
                km: aboutStore?.data?.description?.km
              } | lang : language.lang
            }}
          </p>
        </div> -->
        <div class="des">
          <p>
            <strong>Funan Farm</strong> is a mini mart that focus on local
            safety agriculture products such as vegetables, fruits, fish, meat
            and processing products.
          </p>
          <p>
            <u>Mission : </u>
          </p>
          <ul>
            <li>Convenience Store</li>
            <li>Increase safety food for public health</li>
            <li>Increase more jobs for farmers and youths</li>
            <li>Agriculture processing for local and export</li>
          </ul>
          <p>
            <u>Vision : </u>
          </p>
          <ul>
            <li>
              Become a leading company that supply safety agriculture products
              in the market.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="aboutStore.loading" class="loading"></app-loading>
