import { Injectable } from "@angular/core";
import { observable } from "mobx";
import { action } from "mobx";


@Injectable()

export class LanguageStore {
  @observable lang: string;

  constructor() { }

  @action
  changeLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.getLang();
  }

  @action
  getLang() {
    this.lang = localStorage.getItem('lang');
    if (!this.lang || !["km", "en"].includes(this.lang)) {
      this.lang = 'km';
    }
  }

}

