import { finalize, first, map, tap } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { action, observable } from 'mobx';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConvertService } from '../services/convert.service';
import * as moment from 'moment';
import { IUser } from '../interfaces/IUser';
import { DataService } from '../services/data.service';
import { environment } from 'src/environments/environment';
firebase.initializeApp(environment.firebase);

@Injectable()
export class AuthStore {
  @observable currentUser: any;
  @observable loading;
  @observable user = null;
  @observable empty: boolean;
  selectedFiles: any;
  uploadFolder: any;
  uploads: any[];
  file: any;
  allPercentage: Observable<any>;
  constructor(
    private ds: DataService,
    private auth: AuthService,
    public storage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
  ) {
    // this.fetchCanActive();
  }


  @action
  fetchCanActive() {
    this.loading = true;
    this.auth.canActiveRef().subscribe(user => {
      if (user) {
        this.empty = true;
        this.ds.user().doc<any>(user.uid).valueChanges().subscribe(doc => {
          const { email, fullName, key, profileImageUrl } = doc;
          this.user = {
            key: key,
            name: fullName,
            firstName: doc.firstName,
            lastName: doc.lastName,
            email: email,
            cover: profileImageUrl,
            phone: doc.phoneNumber,
            shippingAddress: doc.shippingAddress
          };
          this.loading = false;
        });
      } else {
        this.empty = false;
      }
      this.loading = false;
    });
  }
  @action
  changeCover(key, item, callback) {
    if (item) {
      this.loading = true;
      this.uploads = [];
      const allPercentage: Observable<number>[] = [];
      for (const file of item) {
        const name = Math.random().toString(36).substring(7) + new Date().getTime() + file.name;
        const path = `customer/${key}/${name}`;
        const ref = this.storage.ref(path);
        const task = this.storage.upload(path, file);
        const _percentage$ = task.percentageChanges();
        allPercentage.push(_percentage$);
        const uploadTrack = {
          fileName: name,
          percentage: _percentage$
        };
        this.uploads.push(uploadTrack);
        const _t = task.then((f) => {
          return f.ref.getDownloadURL().then((url) => {
            return this.ds.user().doc(key).update({
              key: key,
              profileImageUrl: url
            }).then(() => {
              this.loading = false;
              callback(true, null);
            }).catch(error => {
              this.loading = false;
              callback(false, null);
            });
          });
        });
      }
      this.allPercentage = combineLatest(allPercentage)
        .pipe(
          map((percentages) => {
            let result = 0;
            for (const percentage of percentages) {
              result = result + percentage;
            }
            return result / percentages.length;
          }),
          tap(console.log)
        );
    }
    //callback(true, null);
  }
  @action
  updateUser(item, key, callback) {
    this.loading = true;
    this.ds.user().doc(key).update({
      shippingAddress: item.shippingAddress,
      update_by: new Date(),
    }).then(() => {
      this.loading = false;
      callback(true, null);
    }).catch(error => {
      this.loading = false;
      callback(false, null);
    });
  }
  @action
  async FacebookAuth(callback?: any) {
    const provider = new firebase.auth.FacebookAuthProvider();
    this.AuthLogin(provider, (res) => {
      if (callback) {
        callback(res);
      }
    });
  }
  @action
  AuthLogin(provider, callback?: any) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        const data: IUser = {
          key: user.uid,
          name: user.displayName,
          profile: user.photoURL,
          created_date: new Date,
          user_type: {
            key: 2,
            text: "Customer"
          },
          social_login: true,
          verify: true,
        }
        this.save(data, (res, doc) => {
          if (callback) {
            callback(true);
          }
          this.router.navigate(['']);
        });
      }).catch((error) => {
      })
  }
  @action
  save(data: any, callback: any) {
    this.ds.user().doc(data.key).set(data).then((res: any) => {
      callback(true, res);
    }).catch((error) => {
      callback(false, error);
    });
  }
  @action
  registerUser(user, uid, callback) {
    const { firstName, lastName, email, page_key } = user;
    this.ds.user().doc(uid).set({
      active_by: null,
      active_date: null,
      create_by: null,
      create_date: new Date(),
      email: email,
      expiredDate: null,
      expiredDateKey: null,
      firstName: firstName,
      fullName: firstName + ' ' + lastName,
      isPaid: false,
      key: uid,
      lastName: lastName,
      page_key: page_key,
      phone: user.phone,
      phoneNumber: user.phoneNumber,
      product: null,
      profileImageUrl: null,
      shippingAddress: null,
      status: null,
      update_by: null,
      update_date: new Date(),
    }).then(() => {
      this.loading = false;
      callback(true, null);
      this.fetchCanActive();
    }).catch(error => {
      this.loading = false;
      callback(false, error);
    });
  }
  @action
  uniqueEmail(email: string, callback: any) {
    const conEmail = email.toLowerCase();
    if (conEmail) {
      this.db.collection('users', ref => ref.where('email', '==', conEmail).where('verify', '==', true)).valueChanges().subscribe(doc => {
        if (doc.length != 0) {
          if (callback) {
            callback(true);
          }
        } else {
          if (callback) {
            callback(false);
          }
        }
      });
    }
  }

  @action
  uniquePhone(phone: string, callback: any) {
    const conPhone = '0' + Number(phone);
    if (conPhone) {
      this.db.collection('users', ref => ref.where('phone', '==', conPhone).where('verify', '==', true)).valueChanges().subscribe(doc => {
        if (doc.length != 0) {
          if (callback) {
            callback(true);
          }
        } else {
          if (callback) {
            callback(false);
          }
        }
      });
    }
  }

  @action
  checkAuth(callback: any) {
    this.loading = true;
    this.afAuth.authState.subscribe((item) => {
      if (item) {
        this.ds
          .userRef()
          .doc(item.uid)
          .valueChanges()
          .subscribe((item) => {
            this.currentUser = item;
            callback(item);
          });
      } else {
        callback(false);
      }
      this.loading = false;
    });
  }
  changeProfile(image: File, callback?: any) {
    let sub_name: string = moment().format("YYYYMMDDhhmmss");
    let message: string;
    const file: File = image;
    let result: any;
    if (!image) {
      message = "Please select a file.";
      return;
    }

    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      message = "Only images are supported.";
      return;
    }

    if (sub_name) {
      const filePath = `user/${sub_name}${file.name}`;
      const fileRef = this.storage.ref(filePath);
      message = "Profile is uploading. Please wait!";
      if (callback) {
        callback(message, false);
      }
      const task = this.storage.upload(filePath, file);
      let uploadPercent = task.percentageChanges();
      uploadPercent.subscribe((docs) => {
      });
      task
        .snapshotChanges()
        .pipe(finalize(() => {
          let data = fileRef.getDownloadURL();
          if (data) {
            data.subscribe((docs) => {
              message = "Profile has been upload.";
              if (callback) {
                callback(message, docs);
              }
            });
          }
        }))
        .subscribe((docs) => {
        });
    }
  }

  @action
  update(data: any, callback: any) {
    this.ds.userRef().doc(data.key).update(data).then((res: any) => {
      callback(true, res);
    }).catch((error) => {
      callback(false, error);
    });
  }

  @action
  signOut(callback: any) {
    this.afAuth.signOut().then(() => {
      this.currentUser = false;
      callback(true);
    }).then((error) => {
      callback(false);
    });
  }

  @action
  async getUser(key: string, callback?: any) {
    const docs = await this.ds.userRef().doc(key).get().toPromise();
    if (callback) {
      callback(docs.data());
    }
  }

  @action
  delete(data: any, callback: any) {
    this.ds.userRef().doc(data.key).delete().then((res: any) => {
      callback(true, res);
    }).catch((error) => {
      callback(false, error);
    });
  }
  @action
  async checkLogin(callback: any) {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    const loggedIn = !!user;
    if (!loggedIn) {
      callback(loggedIn, user);
    } else {
      callback(loggedIn, user);
    }
  }
}
