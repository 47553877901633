export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCf2_2V2aHgi8ZCCC9jV4sDc2nSFzTOiYY",
    authDomain: "funan-farm-5782e.firebaseapp.com",
    projectId: "funan-farm-5782e",
    storageBucket: "funan-farm-5782e.appspot.com",
    messagingSenderId: "339639602381",
    appId: "1:339639602381:web:5ccda401912be0a04d6d84",
    measurementId: "G-HDRB7D521M"
  }
};
