import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { tap, map, take } from 'rxjs/operators';
import { SignInComponent } from '../components/sign-in/sign-in.component';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.authState.pipe(take(1)).pipe(map(user => !!user)).pipe(tap(async loggedIn => {
      if (loggedIn) {
        return true;
      }
      this.router.navigate(['']);
      this.dialog.open(SignInComponent, {
        width: "700px",
        panelClass: "dialog-login-class",
        autoFocus: false,
      });
      return false;
    }))
  }
}
