import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICart } from 'src/app/interfaces/ICart';
import { IMessage, IMessagePopup } from 'src/app/interfaces/IMessage';
import { DataService } from 'src/app/services/data.service';
import { AuthStore } from 'src/app/stores/auth.store';
import { CartStore } from 'src/app/stores/cart.store';
import { ContactStore } from 'src/app/stores/contact.store';
import { LanguageStore } from 'src/app/stores/language.store';
import { ProductStore } from 'src/app/stores/product.store';
import { BuyNowComponent } from '../buy-now/buy-now.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MessageComponent } from '../message/message.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-popup-detail',
  templateUrl: './popup-detail.component.html',
  styleUrls: ['./popup-detail.component.scss']
})
export class PopupDetailComponent implements OnInit {





  // data: any;
  quantity: number = 1;
  showImage: string;
  video: string = "";
  indexSelected: number = 0;

  totalPrice: number;
  originalPrice: number;
  opt: number = 1;
  qtyString: string;

  process: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MessageComponent>,

    private router: Router,
    private route: ActivatedRoute,
    public productStore: ProductStore,
    public contactStore: ContactStore,
    private dialog: MatDialog,
    private auth: AuthStore,
    private ds: DataService,
    private cartStore: CartStore,
    public language: LanguageStore,


  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.opt = this.data.min_qty / this.data.unit_per_sale;
    this.calculate();
    this.contactStore.fetchContact();
  }


  imageClick(url: any, video?: string, index?: number) {
    this.showImage = url;
    this.indexSelected = index;
    if (video && video != "") {
      this.video = video;
    } else {
      this.video = null;
    }
  }
  onClose() {
    this.dialogRef.close();
  }

  qty(cal: string) {

    if (cal === "+") {
      this.opt++;
    }
    else {
      if (this.opt > 1) {
        this.opt--;
      }
    }

    // if (this.data.uom.uom_parent) {
    //   this.qtyString = "";
    //   if (this.data.uom.uom_parent) {
    //     if (Math.floor((1 / this.data.uom.uom_parent.qty) * this.opt) > 0)
    //       this.qtyString = `${Math.floor((1 / this.data.uom.uom_parent.qty) * this.opt)} ${this.data.uom.uom_parent.name.km}`;
    //   }
    //   if (this.opt % this.data.uom.uom_parent.qty > 0) {
    //     if (this.qtyString != "") {
    //       this.qtyString += ", ";
    //     }
    //     this.qtyString += `${this.opt % this.data.uom.uom_parent.qty} ${this.data.uom.name.km}`;
    //   }
    // } else {
    //   this.qtyString = `${this.opt} ${this.data.uom.name.km}`;
    // }
    this.calculate();
  }

  calculate() {
    let total1 = Number((this.data.price * this.data.discount) / 100);
    this.totalPrice = Number(this.data.price - total1) * this.opt;
    this.originalPrice = Number(this.data.price - total1);
  }

  call(link: string, page?: boolean) {
    let social = window.open(link, page ? "_blank" : '_self');
  }
  toDetail(id: string) {
    this.router.navigate(["detail", id]);
  }
  social(link: string, page?: boolean) {
    let social = window.open(link, page ? "_blank" : '_self');
  }

  addToCart(item: any, relate?: boolean) {
    this.auth.checkLogin((res, user) => {
      if (res != true) {
        this.dialog.open(SignInComponent, {
          width: "700px",
          panelClass: "dialog-login-class",
          autoFocus: false,
        });
      }
      else {
        const data: IMessage = {
          title: {
            km: "បន្ថែមទៅកន្ត្រក",
            en: "Add To Cart"
          },
          msg: {
            km: `តើអ្នកចង់បន្ថែម ទៅកន្ត្រកមែនឬទេ?`,
            en: `Are you want to add to cart?`
          },
          textButton: {
            km: "បន្ថែមទៅកន្ត្រក",
            en: "Add to Cart"
          },
        };
        const result = this.dialog.open(ConfirmDialogComponent, {
          data: data,
          panelClass: 'confirm-dialog',
          width: '300px',
          autoFocus: false
        });
        result.afterClosed().subscribe((res) => {
          if (res) {
            this.process = true;
            const order: ICart = {
              key: this.ds.createKey(),
              product_key: item.key,
              qty: !relate ? this.opt : item.min_qty / item.unit_per_sale,
              created_date: new Date(),
            }

            this.cartStore.addCart(order, user.uid, (res) => {
              // if (res) {
              //   this.opt = 1;
              //   if (!relate) {
              //     this.calculate();
              //   }
              //   this.dialogMessage({
              //     title: {
              //       km: "ជោគជ័យ",
              //       en: "Success"
              //     },
              //     msg: {
              //       km: "ប្រតិបត្តិការណ៍ទទួលបានជោគជួយ។",
              //       en: "Success"
              //     },
              //     duration: 3
              //   });
              // } else {
              //   this.dialogMessage({
              //     title: {
              //       km: "បរាជ័យ",
              //       en: "Fault"
              //     },
              //     msg: {
              //       km: "ប្រតិបត្តិការណ៍បានបរាជ័យ សូមព្យាយាមម្ដងទៀត។",
              //       en: "Process Fault, Please Try Again"
              //     },
              //     duration: 3
              //   });
              // }
              // this.process = false;
            });
          }
        });
      }
    });

  }

  dialogMessage(option: IMessagePopup) {
    this.dialog.open(MessageComponent, {
      data: option,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
  }

  popBuyNow(item: any, qty?: any) {
    const data = {
      ...item,
      qty: qty || null
    }
    this.auth.checkLogin((res, user) => {
      if (res != true) {
        this.dialog.open(SignInComponent, {
          width: "700px",
          panelClass: "dialog-login-class",
          autoFocus: false,
        });
      }
      else {
        this.dialog.open(BuyNowComponent, {
          width: "350px",
          panelClass: "dialog-buy-now",
          autoFocus: false,
          data: data
        });
      }
    })
  }

}
