import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageComponent } from 'src/app/components/message/message.component';
import { IMessagePopup } from 'src/app/interfaces/IMessage';
import { AuthStore } from 'src/app/stores/auth.store';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  user: any;
  phone: AbstractControl;
  address: AbstractControl;
  name: AbstractControl;

  uploading: boolean;

  constructor(
    private fb: FormBuilder,
    private authStore: AuthStore,
    private dialog: MatDialog,
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      phone: [null,[Validators.required, Validators.pattern("[0]{1}[1-9]{1}[0-9]{7,8}")]],
      address: [null, Validators.required]
    });
    this.phone = this.form.controls['phone'];
    this.address = this.form.controls['address'];
    this.name = this.form.controls['name'];
    this.authStore.checkAuth((user) => {
      this.user = user;
      this.form.patchValue({
        name: user.name,
        phone: user.contact_phone,
        address: user.address
      });
    });
  }
  saveChange(form: any) {
    if (this.form.valid) {
      const { name, phone, address } = form;
      const item = {
        key: this.user.key,
        name: name,
        contact_phone: phone,
        address: address

      }
      this.authStore.update(item, (res) => {
        if (res) {
          this.dialogMessage({
            title: {
              km: "ជោគជ័យ",
              en: "Success"
            },
            msg: {
              km: "ប្រតិបត្តិការណ៍ទទួលបានជោគជួយ។",
              en: "Your process is successful"
            },
            duration: 3
          });
        }
      });
    }
  }

  async onSelectFile() {
    let files: any = await this.selectFile("image/*", false);
    if (files) {
      this.uploading = true;
    }
    this.authStore.changeProfile(files, (res, data) => {
      if (data) {
        const item = {
          key: this.user.key,
          profile: data
        };
        this.authStore.update(item, (res) => {
          this.uploading = false;
        })
      }
    });
  }

  selectFile(contentType, multiple) {
    return new Promise(resolve => {
      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = multiple;
      input.accept = contentType;

      input.onchange = _ => {
        let files = Array.from(input.files);
        if (multiple)
          resolve(files);
        else
          resolve(files[0]);
      };

      input.click();
    });
  }

  dialogMessage(option: IMessagePopup) {
    this.dialog.open(MessageComponent, {
      data: option,
      panelClass: 'confirm-dialog',
      width: '300px',
      autoFocus: false
    });
  }

}
