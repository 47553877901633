<div class="sign-in">
  <div class="container">
    <div class="left">
      <img src="./assets/images/logo/logo-removebg-preview (1).png" alt="">
    </div>
    <div class="right">
      <div class="title">
        <p>{{ {en:'Login', km:'ចូលគណនី'} | lang: language.lang }}</p>
      </div>
      <div class="fill-input" [formGroup]="loginForm" *ngIf="!sentCode">
        <div class="input">
          <div class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>{{ {en:'Phone Number', km:'លេខទូរស័ព្ទ '} | lang: language.lang }}</mat-label>
              <mat-icon matPrefix>phone</mat-icon>
              <input type="text" matInput formControlName="phone" autocomplete="off" placeholder="Ex. 0123456789">
              <mat-error *ngIf="phone.hasError('pattern') && !phone.hasError('required')">
                {{ {en:'Wrong Phone Number', km:'លេខទូរស័ព្ទមិនត្រឹមត្រូវ'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="phone.hasError('required')">
                {{ {en:'Please Enter Phone Number', km:'សូមបញ្ចូលលេខទូរស័ព្ទ'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="!phone.hasError('pattern') && !phone.hasError('required') && phone.errors?.unique">
                {{ {en:'This Phone Number is not yet register', km:'លេខទូរស័ព្ទមិនទាន់បានចុះឈ្មោះ'} | lang:
                language.lang }}
              </mat-error>
            </mat-form-field>
            <div id="recaptcha-container"></div>
          </div>
          <div class="login">
            <button (click)="onSubmit(loginForm.value)" matRipple [disabled]="loading">
              <span>{{ {en:'Login', km:'ចូលគណនី'} | lang: language.lang }}</span>
              <span *ngIf="loading">...</span>
              <mat-spinner *ngIf="loading"></mat-spinner>
            </button>
          </div>
          <!-- <div class="check" (click)="popForgetPw()" (click)="close()">
            <p>ភ្លេចលេខសំងាត់របស់អ្នក?</p>
          </div> -->
          <div class="login-with">
            <div class="facebook" (click)="signInWithFB()" matRipple>
              <i class="fab fa-facebook-f"></i>
              <span>{{ {en:'Login with Facebook', km:'ចូលជាមួយហ្វេសប៊ុក'} | lang: language.lang }}</span>
            </div>
          </div>
          <div class="create-acc" (click)="popsignup()" (click)="close()">
            <p>{{ {en:'Do not have account yet?', km:'មិនទាន់មានគណនីទេ?'} | lang: language.lang }}</p><strong>{{
              {en:'Create Account', km:'បង្កើតគណនី'} | lang: language.lang }}</strong>
          </div>
        </div>
      </div>

      <div class="fill-input" *ngIf="sentCode" [formGroup]="verifyForm">
        <div class="input">
          <div class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>{{ {en:'Verify Code', km:'លេខកូដផ្ទៀងផ្ទាត់'} | lang: language.lang }} </mat-label>
              <mat-icon matPrefix>sms</mat-icon>
              <input type="text" matInput formControlName="code" autocomplete="off"
                placeholder="{{ {en:'Verify Code', km:'លេខកូដផ្ទៀងផ្ទាត់'} | lang: language.lang }}">
              <mat-error *ngIf="code.hasError('required')">
                {{ {en:'Please Enter Verify Code', km:'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="code.errors?.wrong_code">
                {{ {en:'Wrong Verify Code', km:'លេខកូដផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ'} | lang: language.lang }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="login">
            <button [disabled]="loading" matRipple (click)="onVerify(verifyForm.value)">
              <span>{{ {en:'Confirm', km:'យល់ព្រម'} | lang: language.lang }}</span>
              <span *ngIf="loading">...</span>
              <mat-spinner *ngIf="loading"></mat-spinner>
            </button>
          </div>
          <div class="create-acc" (click)="popsignup()" (click)="close()">
            <p>{{ {en:'Do Not Have Account Yet?', km:'មិនទាន់មានគណនីទេ?'} | lang: language.lang }}?</p><strong>{{
              {en:'Create Account', km:'បង្កើតគណនី'} | lang: language.lang }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
