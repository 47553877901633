import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'embedLink'
})
export class EmbedLinkPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {

  }

  transform(value: any): any {
    let checkUrl: any;
    if (value) {
      if (this.CheckUrl(value, "youtube") != -1 || this.CheckUrl(value, "youtu") != -1) {
        let lastSlash = value.lastIndexOf('/') + 1;
        let lastEqual = value.lastIndexOf('=') + 1;
        let idYt = value.slice(lastSlash, value.length);
        if (lastEqual > 0) {
          idYt = value.slice(lastEqual, value.length);
        }
        checkUrl = idYt;
      } else if (this.CheckUrl(value, "facebook") != -1 || this.CheckUrl(value, "fb") != -1) {
        checkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.facebook.com/plugins/video.php?href=${value}`);
      }
    }
    // return this.sanitizer.bypassSecurityTrustResourceUrl(checkUrl);
    return checkUrl;

  }

  CheckUrl(value: any, str: any) {
    let lastEq = value.lastIndexOf('.');
    let string = value.slice(0, lastEq);
    return string.indexOf(str);
  }

}
