import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  closeDuration: number = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MessageComponent>,
    public language: LanguageStore
  ) { }

  ngOnInit() {
    this.closeDuration = this.data.duration;
    setInterval(() => {
      this.closeDuration--;
      if (this.closeDuration == 0) {
        this.onClose();
      }
    }, 1000);
  }

  onClose() {
    this.dialogRef.close();
  }

}
