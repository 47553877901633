<div class="sign-up">
  <div class="container">
    <div class="left">
      <img src="./assets/images/logo/logo-removebg-preview (1).png" alt="">
    </div>
    <div class="right">
      <div class="title">
        <p>{{ {en:'Login', km:'ចូលគណនី'} | lang: language.lang }}</p>
      </div>
      <div class="fill-input" *ngIf="!sentCode" [formGroup]="registerForm">
        <div class="input">
          <div class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>{{ {en:'Name', km:'ឈ្មោះ'} | lang: language.lang }} </mat-label>
              <mat-icon matPrefix>account_circle</mat-icon>
              <input type="text" matInput formControlName="name" autocomplete="off" placeholder="">
              <mat-error *ngIf="name.hasError('required')">
                {{ {en:'Please Enter Your Name', km:'សូមបញ្ចូលឈ្មោះរបស់អ្នក'} | lang: language.lang }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>{{ {en:'Phone Number', km:'លេខទូរស័ព្ទ'} | lang: language.lang }} </mat-label>
              <mat-icon matPrefix>phone</mat-icon>
              <input type="text" matInput formControlName="phone" autocomplete="off" placeholder="Ex. 0123456789">
              <mat-error *ngIf="phone.hasError('pattern') && !phone.hasError('required')">{{ {en:'Wrong Phone Number',
                km:'លេខទូរស័ព្ទមិនត្រឹមត្រូវ'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="phone.hasError('required')">
                {{ {en:'Please Enter Phone Number', km:'សូមបញ្ចូលលេខទូរស័ព្ទ'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="!phone.hasError('pattern') && !phone.hasError('required') && phone.errors?.unique">
                {{ {en:'This Number Is Already Register', km:'លេខទូរស័ព្ទបានចុះឈ្មោះរួចហើយ'} | lang:
                language.lang}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="signup">
            <button [disabled]="loading" matRipple (click)="onSubmit(registerForm.value)">
              <span>{{ {en:'Login', km:'ចូលគណនី'} | lang: language.lang }}</span>
              <span *ngIf="loading">...</span>
              <mat-spinner *ngIf="loading"></mat-spinner>
            </button>
          </div>
          <!-- <div class="check">
            <p>ភ្លេចលេខសំងាត់របស់អ្នក?</p>
          </div> -->
          <div class="login-with">
            <div class="facebook" (click)="signInWithFB()">
              <i class="fab fa-facebook-f"></i>
              <span>{{ {en:'Create With Facebook', km:'បង្កើតគណនីជាមួយហ្វេសប៊ុក'} | lang: language.lang }}</span>
            </div>
          </div>
          <div class="create-acc" (click)="poplogin()" (click)="close()">
            <p>{{ {en:'Or Login With Your Account?', km:'ឬប្រើគណនីរបស់អ្នក?'} | lang: language.lang }}</p><strong> {{
              {en:'Login',
              km:'ចូលគណនី'} | lang: language.lang }}</strong>
          </div>
        </div>
      </div>
      <div class="fill-input" *ngIf="sentCode" [formGroup]="verifyForm">
        <div class="input">
          <div class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>{{ {en:'Please Confirm Code', km:'លេខកូដផ្ទៀងផ្ទាត់'} | lang: language.lang }} </mat-label>
              <mat-icon matPrefix>sms</mat-icon>
              <input type="text" matInput formControlName="code" autocomplete="off" placeholder="លេខកូដផ្ទៀងផ្ទាត់">
              <mat-error *ngIf="code.hasError('required')">
                {{ {en:'Please Enter Confirm Code', km:'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="code.errors?.wrong_code">
                {{ {en:'Wrong Code', km:'លេខកូដផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ'} | lang: language.lang }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="signup">
            <button [disabled]="loading" matRipple (click)="onVerify(verifyForm.value)">
              <span>{{ {en:'Yes', km:'យល់ព្រម'} | lang: language.lang }} </span>
              <span *ngIf="loading">...</span>
              <mat-spinner *ngIf="loading"></mat-spinner>
            </button>
          </div>
          <div class="create-acc" (click)="poplogin()" (click)="close()">
            <p>{{ {en:'Or Login?', km:'ឬប្រើគណនីរបស់អ្នក?'} | lang: language.lang }} </p><strong> {{ {en:'Login',
              km:'ចូលគណនី'} | lang: language.lang }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="recaptcha-container"></div>
