import { Injectable } from '@angular/core';
import { firestore } from 'firebase';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor() { }
  static pushToArray(snapshot: firestore.QuerySnapshot) {
    if (snapshot.empty) return [];
    return snapshot.docs.map(m => ({ ...m.data(), id: m.id }));
  }
  static pushToObject(snapshot: firestore.DocumentSnapshot) {
    if (!snapshot.exists) return null;
    return { ...snapshot.data(), id: snapshot.id }
  }
  static limit(data: Array<any>, limit: number) {
    return _.take(data, limit)
  }
}
