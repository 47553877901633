import { Component, OnInit } from '@angular/core';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
  }

}
