<div class="confirm-wrapper">
  <div class="confirm-body">
    <h4>{{ {en:data?.title?.en, km:data?.title?.km} | lang: language.lang}}</h4>
    <p>{{ {en:data?.msg?.en, km:data?.msg?.km} | lang: language.lang}}</p>
  </div>
  <div class="confirm-footer">
    <button mat-flat-button class="close" (click)="onClose()" tabindex="-1">{{ {en:"Close", km:"បិទ"} | lang:
      language.lang}}</button>
    <button mat-flat-button (click)="onOK()">{{ {en:data?.textButton?.en, km:data?.textButton?.km} | lang:
      language.lang}}</button>
  </div>
</div>