import { Injectable } from "@angular/core";
import { action, observable } from "mobx-angular";
import { OrderService } from "../services/data/order.service";
import { MappingService } from "../services/mapping.service";

@Injectable()
export class OrderStore {

    @observable orderList: any[];
    @observable orderDetail: any;
    empty: boolean;

    loading: boolean;

    constructor(
        private ds: OrderService
    ) { }

    @action
    fetchOrder(userKey: string) {
        this.loading = true;
        this.empty = false;
        this.ds.getOrderByUser(userKey).valueChanges().subscribe((res) => {
            this.orderList = res;
            if (res.length <= 0) {
                this.empty = true;
            }
            this.loading = false;
        });
    }

    async fetchOrderByKey(key: string) {
        this.loading = true;
        const doc = await this.ds.orderRef(key).get().toPromise();
        this.orderDetail = doc.data();
        this.loading = false;
    }

    async getLastOrder(callback: any) {
        const doc = await this.ds.getLastOrderByOrderNo().get().toPromise();
        const data = MappingService.pushToArray(doc);
        callback(data);
    }

    @action
    save(data: any, callback: any) {
        this.ds.orderRef(data.key).set(data).then((res) => {
            callback(true);
        }).catch((error) => {
            callback(false);
        });
    }

}