import { Component, OnInit } from '@angular/core';
import { AboutStore } from 'src/app/stores/about.store';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    public aboutStore: AboutStore,
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {
    this.aboutStore.fetchAbout();
  }

}
