<ng-container *ngIf="!orderStore.loading">
  <div class="order-list">
    <div class="has-fixed"></div>
    <div class="content fixed">
      <app-empty *ngIf="orderStore?.empty"></app-empty>
      <div class="container-order-list" *ngIf="!orderStore?.empty">
        <div class="item-order-list" *ngFor="let item of orderStore.orderList">
          <mat-expansion-panel class="width">
            <mat-expansion-panel-header>
              <mat-panel-title class="top">
                <div class="container-top">
                  <div class="image pending" *ngIf="item.status.key == 1 || item.status.key == 2">
                    <i class="far fa-clock"></i>
                  </div>
                  <div class="image cancel" *ngIf="item.status.key == 3">
                    <i class="fas fa-times"></i>
                  </div>
                  <div class="image complete" *ngIf="item.status.key == 4">
                    <i class="fas fa-check"></i>
                  </div>
                  <div class="price">
                    <div class="item-price">
                      <p>{{ {km:"តម្លៃសរុប",en:"Total Price"} | lang:language.lang }} : </p><span>{{item?.total_price |
                        number:'1.0'}} ៛</span>
                    </div>
                    <div class="item-price">
                      <p>{{ {km:"បញ្ចុះតម្លៃ",en:"Discount"} | lang:language.lang }} : </p><span>-
                        {{item?.total_discount | number:'1.0'}} ៛</span>
                    </div>
                    <div class="item-price">
                      <p>{{ {km:"លេខសម្គាល់",en:"Order No."} | lang:language.lang }} : </p><span>{{item?.order_no}}</span>
                    </div>
                  </div>
                  <div class="text">
                    <div class="item-text">
                      <p>{{ {km:"ឈ្មោះ",en:"Name"} | lang:language.lang }} : </p><span>{{item?.name}}</span>
                    </div>
                    <div class="item-text">
                      <p>{{ {km:"លេខទូរស័ព្ទ",en:"Phone"} | lang:language.lang }} : </p>
                      <span>{{item?.phone}}</span>
                    </div>
                    <div class="item-text">
                      <p>{{ {km:"អាសយដ្ឋាន",en:"Address"} | lang:language.lang }} : </p><span>{{item?.address}}</span>
                    </div>
                  </div>
                  <div class="date">
                    <p>{{item?.created_date?.toDate() | date:'dd-MMMM-yyyy'}}</p>
                  </div>
                </div>
              </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="bottom">
              <div class="item-bottom" *ngFor="let product of item?.product">
                <p>+ {{ {km:product.name.km,en:product.name.en} | lang:language.lang }}</p> <span>(
                  {{{data:product,qty:product.qty*product.unit_per_sale} | convertUom:language.lang}} x {{product?.price
                  |
                  number:'1.0'}} ៛/{{ {km:product.uom.name.km,en:product.uom.name.en} | lang:language.lang }})</span>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="orderStore.loading" class="loading"></app-loading>