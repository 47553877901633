import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { observable, action } from 'mobx-angular';
import { MappingService } from '../services/mapping.service';
import * as _ from 'lodash';

@Injectable()
export class ProductStore {

  @observable data: any;
  @observable loading: boolean;
  @observable dataRelate: any[];

  @observable disabledNext: boolean;
  @observable disabledBack: boolean;
  @observable empty: boolean;
  dataCategory: any[];
  limit: number = 12;
  count_page: number = 0;
  last_item_date: Date;
  first_item_date: Date;

  constructor(
    private ds: DataService
  ) { }

  @action
  async fetchProduct(option: 'all' | 'next' | 'back', firstOrLastItemDate?: Date, categoryKey?: string, keyword?: string) {
    if (option == 'all') {
      this.disabledNext = true;
      this.disabledBack = true;
      this.count_page = 0;
    }
    this.loading = true;
    const docs = await this.ds.getProduct(option, this.limit, firstOrLastItemDate, categoryKey, keyword).get().toPromise();
    const data = MappingService.pushToArray(docs);
    this.empty = data.length == 0;
    this.disabledNext = data.length < (this.limit + 1);
    switch (option) {
      case 'next':
        this.count_page++;
        break;
      case 'back':
        this.count_page--;
        this.disabledNext = false;
        break;
    }
    this.disabledBack = this.count_page == 0;
    this.data = data.slice(0, this.limit);
    this.first_item_date = this.data[0]?.created_date.toDate();
    this.last_item_date = this.data[this.data.length - 1]?.created_date.toDate();
    if (data) {
      this.loading = false;
    }
  }


  @action
  async fetchDetail(key: any, callback: any) {
    this.loading = true;
    const doc = await this.ds.getDetail(key).get().toPromise();
    const data: any = doc.data();
    this.fetchRelated(data.category, key);
    callback(data);
    if (data) {
      this.loading = false;
    }
  }


  @action
  async fetchRelated(cateKey: any, key: any) {
    const doc = await this.ds.productRelate(cateKey).get().toPromise();
    const data = MappingService.pushToArray(doc);
    let result = data.filter((item: any) => {
      if (key != item.key) {
        return item;
      }
    });
    this.dataRelate = result.slice(0, 4);
  }
  @action
  async fetchCategory() {
    const docs = await this.ds.getCategory().get().toPromise();
    const data = MappingService.pushToArray(docs);
    this.dataCategory = data;
  }


}
