<div class="profile">
  <div class="has-fixed"></div>
  <div class="content fixed">
    <div class="container-profile">
      <div class="left">
        <div class="image">
          <ng-container *ngIf="!uploading ">
            <img [src]="user?.profile " #profile (error)="profile.src='./assets/images/photo/profile.png' " alt="">
          </ng-container>
          <mat-spinner *ngIf="uploading" [diameter]="50" strokeWidth="2"></mat-spinner>
        </div>
        <div class="button">
          <button mat-icon-button class="delete-icon">
            <mat-icon class="mat-18" (click)="onSelectFile()">photo_camera</mat-icon>
          </button>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <h3>{{ {en:'Your Information', km:'ព័ត៌មានផ្ទាល់ខ្លួន'} | lang: language.lang }}</h3>
        </div>
        <div class="text" [formGroup]="form">
          <div class="name">
            <div class="input">
              <p>{{ {en:'Name', km:'ឈ្មោះ'} | lang: language.lang }}</p>
              <input type="text" matInput formControlName="name">
              <mat-error *ngIf="name.hasError('required')">
                {{ {en:'Please Enter Your Name', km:'សូមបញ្ចូលឈ្មោះ'} | lang: language.lang }}
              </mat-error>
            </div>
            <div class="input">
              <p>{{ {en:'Phone Number', km:'លេខទូរស័ព្ទ'} | lang: language.lang }}</p>
              <input type="text" matInput formControlName="phone">
              <mat-error *ngIf="phone.hasError('pattern') && !phone.hasError('required')">
                {{ {en:'Wrong Phone Number', km:'លេខទូរស័ព្ទមិនត្រឹមត្រូវ'} | lang: language.lang }}
              </mat-error>
              <mat-error *ngIf="phone.hasError('required')">
                {{ {en:'Please Enter Your Phone Number', km:'សូមបញ្ចូលលេខទូរស័ព្ទ'} | lang: language.lang }}
              </mat-error>
            </div>
          </div>
          <div class="address">
            <p>{{ {en:'Address', km:'អាសយដ្ឋាន'} | lang: language.lang }}</p>
            <textarea matInput formControlName="address"
              placeholder="{{ {en:'In Put Your Address', km:'សសេរសារទីនេះ...'} | lang: language.lang }}"></textarea>
            <mat-error *ngIf="address.hasError('required')">
              {{ {en:'Please Enter Your Address', km:'សូមបញ្ចូលអាសយដ្ឋាន'} | lang: language.lang }}
            </mat-error>
          </div>
          <div class="button">
            <button mat-flat-button class="button-edit" (click)=" saveChange(form.value)"><span>{{ {en:'Save',
                km:'រក្សាទុក'} | lang: language.lang }}</span></button>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
