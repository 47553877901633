<div class="forget-pw">
  <div class="title">
    <h3>ភ្លេចលេខសំងាត់របស់អ្នក!</h3>
  </div>
  <div class="image">
    <img src="./assets/images/logo/logo-removebg-preview (1).png" alt="">
  </div>
  <div class="title">
    <p>សូមបញ្ចូលអ៉ីម៉ែលរបស់អ្នក</p>
  </div>
  <div class="input-forget-pw">
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <mat-label>អ៉ីម៉ែល </mat-label>
        <mat-icon matPrefix>email</mat-icon>
        <input type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
        <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="email.hasError('required')">
          សូមបញ្ចូលអ៉ីម៉ែលរបស់អ្នក
        </mat-error>
      </mat-form-field>
    </form>

  </div>
  <button mat-flat-button class="button-forget"><span>ផ្ញើតំណកំណត់ឡើងវិញ</span></button>
</div>
