<div class="footer">
  <div class="content fixed">
    <div class="container-footer">
      <div class="copy-right">
        <p>{{ {en:' CopyRight © 2021 FUNAN FARM', km:' រក្សាសិទ្ធិ © 2021 ហ្វូណន ហ្វាម'} | lang: language.lang }} </p>
      </div>
      <div class="button">
        <button class="facebook" *ngIf="ifNull(contactStore?.data?.social?.facebook)"
          (click)="Social(contactStore?.data?.social?.facebook)"><i class="fab fa-facebook-f"></i></button>
        <button class="telegram" *ngIf="ifNull(contactStore?.data?.social?.telegram)"
          (click)="Social(contactStore?.data?.social?.telegram)"><i class="fab fa-telegram-plane"></i></button>
        <button class="youtube" *ngIf="ifNull(contactStore?.data?.social?.youtube)"
          (click)="Social(contactStore?.data?.social?.youtube)"><i class="fab fa-youtube"></i></button>
      </div>
    </div>

  </div>
</div>
<div class="scrollup" (click)="gotoTop()" *ngIf="isShow">
  <i class="fas fa-chevron-up"></i>
</div>
