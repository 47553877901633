import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { observable, action } from 'mobx-angular';
import { MappingService } from '../services/mapping.service';
import * as _ from 'lodash';

@Injectable()
export class AboutStore {

  @observable data: any;
  @observable loading: boolean;


  constructor(
    private ds: DataService
  ) { }
  @action
  async fetchAbout() {
    this.loading = true;
    const docs = await this.ds.getAbout().get().toPromise();
    const data = MappingService.pushToArray(docs);
    this.data = data[0];
    if (data) {
      this.loading = false;
    }
  }


}
