import { Component, HostListener, OnInit } from '@angular/core';
import { ContactStore } from 'src/app/stores/contact.store';
import { LanguageStore } from 'src/app/stores/language.store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 100;
  constructor(
    public contactStore: ContactStore,
    public language: LanguageStore,
  ) { }

  ngOnInit(): void {

  }
  Social(link: string) {
    let url: string;
    if (link != null) {
      if (link.indexOf('http') != -1) {
        url = link;
      } else {
        url = 'https://' + link;
      }
    } else {
      url = '#';
    }

    window.open(url, '_blank');
  }
  ifNull(data: any): boolean {
    if (data != "" && data != null) {
      return true;
    } else {
      return false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  @HostListener('window:scroll', [])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
}
